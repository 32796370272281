import { QuestionCircleOutlined } from "@ant-design/icons";
import { ProColumns } from "@ant-design/pro-table";
import { Button, message, Popconfirm, Spin, Switch, Tooltip } from "antd";
import AntdTablePro from "components/antdTablePro";
import {
  useCityDelete,
  useCityListPaginated,
  useCityUpdate,
} from "hooks/queries/useCity";
import { debounce } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiInfoCircle, BiPencil, BiPlus, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { City } from "types/city";

type FilterParams = {
  page: number;
  size: number;
  search: string;
};

function index() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: 100,
    search: "",
  });
  const { data, isLoading } = useCityListPaginated({
    ...filterParams,
    search: filterParams.search.trim() || undefined,
  });

  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      title: t("cities:name"),
      dataIndex: "name",
    },
    {
      title: t("cities:basePrice"),
      dataIndex: "basePrice",
    },
    {
      title: t("cities:permissionPrice"),
      dataIndex: "permissionPrice",
    },
    {
      title: t("cities:withOutsideLiftPrice"),
      dataIndex: "withOutsideLiftPrice",
    },
    {
      title: (
        <div>
          {t("cities:status")}&nbsp;
          <Tooltip placement="bottom" title={t("cities:tooltip:status")}>
            <BiInfoCircle />
          </Tooltip>
        </div>
      ),
      render: (_, row: City) => {
        return <StatusSwitch city={row} />;
      },
    },
    {
      title: t("general:action"),
      render: (_, row: City, index) => [
        <div key={index} className="flex gap-2">
          <Link
            key="edit"
            to={`/city/edit/${row.id}`}
            className="ant-btn ant-btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              background: "#ffb630",
            }}
          >
            <BiPencil />
          </Link>
          <DeleteCity city={row} />
        </div>,
      ],
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, page: 1, search: value });
  }, 500);

  return (
    <AntdTablePro
      columns={columns}
      rowKey="id"
      dataSource={data?.items}
      pagination={{
        showSizeChanger: true,
        showQuickJumper: true,
        current: filterParams?.page,
        pageSize: filterParams?.size,
        total: data?.count,
        onChange: (page, pageSize) => {
          setFilterParams({
            ...filterParams,
            page: page,
            size: pageSize,
          });
        },
      }}
      toolBarRender={[
        <Link
          key="add"
          to="/city/add"
          className="ant-btn ant-btn-primary header-add-link"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="icon">
            <BiPlus />
          </span>
          <span className="text">{t("cities:addCity")}</span>
        </Link>,
      ]}
      inputElement={{
        placeholder: t("main:search"),
        handleChange: (e) => getSearchKeywordsDeb(e.target.value),
        handleSearch: (value) =>
          setFilterParams({ ...filterParams, page: 1, search: value }),
      }}
    />
  );
}

export default index;

function StatusSwitch({ city }: { city: City }) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCityUpdate(city.id);

  const [optimisticCheck, setOptimisticCheck] = useState(!city.isDisabled);

  const onChange = (v: boolean) => {
    setOptimisticCheck(v);
    mutate(
      { isDisabled: !v },
      {
        onSuccess: () => {
          message.success(t("cities:messages.updatedSuccessfully"));
        },
        onError: () => {
          setOptimisticCheck((v) => !v);
          message.error(t("cities:messages.updateFailed"));
        },
      }
    );
  };

  return (
    <Switch
      checked={optimisticCheck}
      onChange={onChange}
      className={optimisticCheck ? "bg-primary" : ""}
      disabled={isLoading}
    />
  );
}

function DeleteCity({ city }: { city: City }) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCityDelete(city.id);

  const onDelete = () => {
    mutate(undefined, {
      onSuccess: () => {
        message.success(t("cities:messages.deletedSuccessfully"));
      },
      onError: () => {
        message.error(t("cities:messages.deleteFailed"));
      },
    });
  };

  return (
    <Popconfirm
      placement="leftBottom"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      title={t("general:confirmDelete")}
      onConfirm={onDelete}
      okText={t("orders:yes")}
      cancelText={t("orders:no")}
    >
      <Button type="primary" disabled={isLoading} style={{ background: "red" }}>
        <BiTrash />
      </Button>
    </Popconfirm>
  );
}
