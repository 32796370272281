import { Divider, Form, message } from "antd";
import {
  useAuthorityCreate,
  useAuthorityFileUpload,
} from "hooks/queries/useAuthority";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import AuthorityForm from "../AuthorityForm";

export default function AddAuthority() {
  const { t } = useTranslation();
  const history = useHistory();

  const [files, setFiles] = useState<File[]>([]);

  const { mutateAsync, isLoading } = useAuthorityCreate();
  const { handleUpload, uploadLoading } = useAuthorityFileUpload();

  const onSubmit = async (data) => {
    const authority = await mutateAsync(data);

    await handleUpload(authority.data.id, files);

    message.success(t("authority:messages.createdSuccessfully"));
    history.push("/authority/all");
  };

  return (
    <Form
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
    >
      <h5>
        <Link to="/authority/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("authority:addAuthority")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <AuthorityForm
        isLoading={uploadLoading || isLoading}
        files={files}
        setFiles={setFiles}
      />
    </Form>
  );
}
