import { Collapse } from "antd";
import OrderTable from "components/orders/OrderTable";
import CollapsePanelHeader from "components/reusable/CollapsePanelHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { isAdmin } from "utils/global";
import { ELIAS_ORDER_EMAIL_ADDRESS } from "utils/global/constants";

type OrderCount = {
  openOrderH: string | undefined;
  openOrderE: string | undefined;
};

function OpenOrder() {
  const { t } = useTranslation();

  const filter = {
    acceptOrder: true,
    rejectOrder: false,
    closeOrder: false,
  };
  const [orderCounts, setOrderCounts] = useState<OrderCount>({
    openOrderH: undefined,
    openOrderE: undefined,
  });

  const handleTotalDataChange = (
    key: keyof OrderCount,
    count: number | undefined
  ) => {
    setOrderCounts((prev) => ({
      ...prev,
      [key]: count,
    }));
  };

  return (
    <div>
      <Collapse>
        <Collapse.Panel
          key="openOrderH"
          header={
            <CollapsePanelHeader
              textKey={t("openOrder:tableHeadings:openOrderH")}
              totalNumber={orderCounts.openOrderH}
            />
          }
          forceRender
        >
          <OrderTable
            pageSize={100}
            order="DESC"
            orderBy="createdAt"
            excludeEmail={ELIAS_ORDER_EMAIL_ADDRESS}
            rowClassName={(record: InstallationSchema) => {
              if (!isAdmin()) return "";
              if (record.closeOrder) return "";
              if (record.rejectOrder) return "installation-bg-gray";
              if (!record.acceptOrder) return "installation-bg-green";
              return "installation-bg-yellow";
            }}
            onTotalDataChange={(count) =>
              handleTotalDataChange("openOrderH", count)
            }
            statusFilter={filter}
          />
        </Collapse.Panel>
      </Collapse>
      <Collapse>
        <Collapse.Panel
          key="openOrderE"
          header={
            <CollapsePanelHeader
              textKey={t("openOrder:tableHeadings:openOrderE")}
              totalNumber={orderCounts.openOrderE}
            />
          }
          forceRender
        >
          <OrderTable
            pageSize={100}
            order="DESC"
            orderBy="createdAt"
            includeEmail={ELIAS_ORDER_EMAIL_ADDRESS}
            rowClassName={(record: InstallationSchema) => {
              if (!isAdmin()) return "";
              if (record.closeOrder) return "";
              if (record.rejectOrder) return "installation-bg-gray";
              if (!record.acceptOrder) return "installation-bg-green";
              return "installation-bg-yellow";
            }}
            onTotalDataChange={(count) =>
              handleTotalDataChange("openOrderE", count)
            }
            statusFilter={filter}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default OpenOrder;
