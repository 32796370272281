import { Divider, Form, message } from "antd";
import { LoadingDiv } from "components";
import { useCityFindOne, useCityUpdate } from "hooks/queries/useCity";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";
import { CityFormBody } from "types/city";
import CityForm from "../CityForm";

export default function EditCity() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { data: city } = useCityFindOne(params.id);

  const { mutate, isLoading } = useCityUpdate(params.id);

  const onSubmit = async (data: CityFormBody) => {
    mutate(data, {
      onSuccess: () => {
        message.success(t("cities:messages.updatedSuccessfully"));
        history.push("/city/all");
      },
      onError: () => {
        message.error(t("cities:messages.updateFailed"));
      },
    });
  };

  return (
    <Form
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
    >
      <h5>
        <Link to="/city/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("cities:editCity")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />

      {!city ? <LoadingDiv /> : <CityForm isLoading={isLoading} city={city} />}
    </Form>
  );
}
