import { INSTALLATION_LIST_QUERY_KEY } from "hooks/queries/useInstallation";
import { GET_STATUS_WISE_COUNT_QUERY_KEY } from "hooks/queries/useStatusWiseCount";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import {
  ConnectInvoiceRequestBodyDTO,
  InvoiceEmail,
  PaymentNoticeInvoiceRequestBodyDTO,
  SendEmailInvoiceRequestBodyDTO,
  UpsertInvoiceRequestBodyDTO,
} from "types/invoice";
import {
  invoiceConnectionKeys,
  invoiceSentEmailKeys,
} from "utils/constant/queryKey";
import { cleanData } from "utils/global";

type InvoicePayRequestBody = {
  invoicePaid: boolean;
  invoicePaidDateTime?: string;
};

export const useInvoicePay = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: InvoicePayRequestBody) =>
      new AuthorizedService()
        .put(`/installation/${id}/invoice-pay`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
        queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
      },
    }
  );
};

export const useInvoiceUpsert = (installationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UpsertInvoiceRequestBodyDTO) => {
      const cleanedData = cleanData(data);
      const formData = new FormData();
      Object.keys(cleanedData).forEach((key) => {
        formData.append(key, cleanedData[key]);
      });

      return new AuthorizedService()
        .post(`/installation/${installationId}/invoice`, formData)
        .then((res) => res);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
    },
  });
};

export const useInvoiceAttachmentDelete = (
  installationId: string,
  invoiceId: string,
  invoiceAttachmentId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return new AuthorizedService()
        .delete(
          `/installation/${installationId}/invoice/${invoiceId}/invoiceAttachment/${invoiceAttachmentId}`
        )
        .then((res) => res);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
    },
  });
};

export const useInvoicePaymentNotice = (installationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PaymentNoticeInvoiceRequestBodyDTO) => {
      return new AuthorizedService()
        .post(`/installation/${installationId}/invoice/notice`, data)
        .then((res) => res);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
    },
  });
};

export const useInvoiceConnect = (
  installationId: string,
  invoiceId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ConnectInvoiceRequestBodyDTO) => {
      return new AuthorizedService()
        .post(
          `/installation/${installationId}/invoice/${invoiceId}/connect`,
          data
        )
        .then((res) => res);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(invoiceConnectionKeys.all);
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
    },
  });
};

export const useInvoiceSentEmailList = (
  installationId: string,
  invoiceId: string
) => {
  return useQuery<{ data: InvoiceEmail[] }>({
    queryKey: invoiceSentEmailKeys.list(installationId, invoiceId),
    queryFn: async () => {
      return new AuthorizedService()
        .get(`/installation/${installationId}/invoice/${invoiceId}/email`)
        .then((res) => res);
    },
  });
};

export const useInvoiceSendEmail = (
  installationId: string,
  invoiceId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: SendEmailInvoiceRequestBodyDTO) => {
      return new AuthorizedService()
        .post(
          `/installation/${installationId}/invoice/${invoiceId}/email`,
          data
        )
        .then((res) => res);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        invoiceSentEmailKeys.list(installationId, invoiceId)
      );
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
    },
  });
};

export const useInvoiceConnectionList = (
  installationId: string,
  invoiceId?: string
) => {
  return useQuery<{ data: string[] }>({
    queryKey: invoiceConnectionKeys.list(installationId, invoiceId),
    queryFn: async () => {
      return new AuthorizedService()
        .get(`/installation/${installationId}/invoice/${invoiceId}/connect`)
        .then((res) => res);
    },
    enabled: !!invoiceId,
  });
};
