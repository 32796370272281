import { Button, Col, ColProps, Form, Input, Radio, Row, RowProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileSelector from "components/reusable/FileSelector";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Authority } from "types/authority";

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

const rowStyles: RowProps = {
  gutter: 16,
};

export default function AuthorityForm({
  isLoading,
  files,
  setFiles,
  deleteUploadedFile,
  authorityDetails,
}: {
  isLoading: boolean;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  deleteUploadedFile?: (id: string) => Promise<void>;
  authorityDetails?: Authority;
}) {
  const { t } = useTranslation();

  const numberValidator = (_, value: string) => {
    if (!value || !value.trim()) {
      return Promise.reject(t("warnings:required"));
    }
    if (isNaN(Number(value))) {
      return Promise.reject(t("warnings:invalidValue"));
    }
    return Promise.resolve();
  };

  const handleFileChange = (files: File[]) => {
    setFiles(files);
  };

  return (
    <>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={authorityDetails?.name}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.address")}
            name="address"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={authorityDetails?.address}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.contactPerson")}
            name="contactPerson"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={authorityDetails?.contactPerson}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
              {
                type: "email",
                message: t("warnings:invalidValue"),
              },
            ]}
            initialValue={authorityDetails?.email}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.phone")}
            name="phone"
            required
            rules={[
              {
                validator: numberValidator,
              },
            ]}
            initialValue={authorityDetails?.phone}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.fax")}
            name="fax"
            required
            rules={[
              {
                validator: numberValidator,
              },
            ]}
            initialValue={authorityDetails?.fax}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.application")}
            name="applicationForm"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={authorityDetails?.applicationForm}
          >
            <Radio.Group>
              <Radio value={true}>{t("authority:field.withForm")}</Radio>
              <Radio value={false}>{t("authority:field.withoutForm")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("authority:field.sendInstallationProtocolToOffice")}
            name="sendInstallationProtocolToOffice"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={authorityDetails?.sendInstallationProtocolToOffice}
          >
            <Radio.Group>
              <Radio value={true}>{t("general:yes")}</Radio>
              <Radio value={false}>{t("general:no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={t("authority:field.hints")}
            name="hints"
            rules={[
              {
                max: 1000,
                message: t("orders:notice.maxLimitExceeded"),
              },
            ]}
            initialValue={authorityDetails?.hints}
          >
            <TextArea style={{ height: "100px" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label={t("authority:field.files")}>
            <FileSelector
              value={files}
              setValue={handleFileChange}
              initialFiles={authorityDetails?.attachments}
              deleteUploadedFile={deleteUploadedFile}
              multiple={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t("general:submit")}
      </Button>
    </>
  );
}
