import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import FileSaver from "file-saver";
import { useInvoiceAttachmentDelete } from "hooks/queries/useInvoice";
import { useTranslation } from "react-i18next";
import { AiFillFilePdf, AiOutlineCloudDownload } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { Invoice, InvoiceAttachment } from "types/invoice";
import {
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_ICON_FONT,
} from "utils/global/constants";

export default function InvoiceFilePreview({
  file,
  onFileChange,
  showInput,
  installationId,
  invoice,
}: {
  installationId: string;
  invoice: Invoice | null;
  file: File | null;
  onFileChange: (file: File | null) => void;
  showInput?: boolean;
}) {
  return (
    <div>
      <div className="flex flex-col gap-2">
        {invoice?.invoiceAttachment && (
          <UploadedFile
            installationId={installationId}
            invoiceId={invoice.id}
            invoiceAttachment={invoice.invoiceAttachment}
            showInput={showInput}
            file={file}
          />
        )}
        {file && (
          <div className="uploaded-list-item">
            <div
              className="flex justify-center items-center cursor-pointer gap-2"
              style={{
                height: IMAGE_PREVIEW_HEIGHT,
              }}
            >
              <AiFillFilePdf fontSize={IMAGE_PREVIEW_ICON_FONT} />
              {file.name}
            </div>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => {
                onFileChange(null);
              }}
              style={{ background: "grey" }}
            >
              <BiTrash />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function UploadedFile({
  installationId,
  invoiceId,
  invoiceAttachment,
  showInput,
  file,
}: {
  installationId: string;
  invoiceId: string;
  invoiceAttachment: InvoiceAttachment;
  showInput?: boolean;
  file: File | null;
}) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useInvoiceAttachmentDelete(
    installationId,
    invoiceId,
    invoiceAttachment.id
  );

  const handleDownload = () => {
    if (!invoiceAttachment) return;
    FileSaver.saveAs(invoiceAttachment.fileUrl, invoiceAttachment.name);
  };

  const onDelete = () => {
    mutate();
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="uploaded-list-item">
        <div
          className="flex justify-center items-center gap-2"
          style={{
            height: IMAGE_PREVIEW_HEIGHT,
          }}
        >
          <AiFillFilePdf fontSize={IMAGE_PREVIEW_ICON_FONT} />
          {invoiceAttachment.name}
        </div>
        {!showInput && (
          <div className="flex gap-2">
            <Button type="primary" onClick={handleDownload}>
              <AiOutlineCloudDownload />
            </Button>
            <Popconfirm
              placement="leftBottom"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              title={t("general:confirmDocumentDelete")}
              onConfirm={onDelete}
              okText={t("orders:yes")}
              cancelText={t("orders:no")}
            >
              <Button
                type="primary"
                loading={isLoading}
                style={{ background: "red" }}
              >
                <BiTrash />
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
      {file && (
        <div className="file-preview-overlay">
          {t("worker:deleteSignatureWarning")}
        </div>
      )}
    </div>
  );
}
