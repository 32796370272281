import i18n from "i18n";
import { Gender } from "types/customer";
import { InstallationSchema } from "types/installation";
import { GERMAN_YES } from "./constants";

const { t } = i18n;

export function getSalutationFromGender(gender: Gender): string {
  const genderMapper = {
    [Gender.MALE]: t("general:male"),
    [Gender.FEMALE]: t("general:female"),
  };

  return genderMapper[gender];
}

export const getUpdatedIsTwoSiteBlocking = (
  installation: InstallationSchema
) => {
  let updatedIsTwoSiteBlocking = installation.isTwoSiteBlock;
  if (installation.additionalInformation?.additionalIsTwoSiteBlock) {
    updatedIsTwoSiteBlocking =
      installation.additionalInformation.additionalIsTwoSiteBlock ===
      GERMAN_YES;
  }

  return updatedIsTwoSiteBlocking;
};

export const getUpdatedWithOutsideLift = (installation: InstallationSchema) => {
  let updatedWithOutsideLift = installation.withOutsideLift;
  if (installation.additionalInformation?.additionalWithOutsideLift) {
    updatedWithOutsideLift =
      installation.additionalInformation.additionalWithOutsideLift ===
      GERMAN_YES;
  }

  return updatedWithOutsideLift;
};

export const getUpdatedStreetAndHouseNumber = (
  installation: InstallationSchema
) => {
  let updatedStreetAndHouseNumber = installation.streetAndHouseNumber;
  if (installation.additionalInformation?.additionalStreetAndHouseNumber) {
    updatedStreetAndHouseNumber =
      installation.additionalInformation.additionalStreetAndHouseNumber;
  }

  return updatedStreetAndHouseNumber;
};

export const getUpdatedCity = (installation: InstallationSchema) => {
  let updatedCity = installation.city.name;
  if (installation.additionalInformation?.additionalCity) {
    updatedCity = installation.additionalInformation.additionalCity;
  }

  return updatedCity;
};

export const getUpdatedZipCode = (installation: InstallationSchema) => {
  let updatedZipCode = installation.zipCode;
  if (installation.additionalInformation?.additionalZipCode) {
    updatedZipCode = installation.additionalInformation.additionalZipCode;
  }

  return updatedZipCode;
};

export const getUpdatedTimeFrom = (installation: InstallationSchema) => {
  let updatedTimeFrom = installation.timeFrom;
  if (installation.additionalInformation?.additionalTimeFrom) {
    updatedTimeFrom = installation.additionalInformation.additionalTimeFrom;
  }

  return updatedTimeFrom;
};

export const getUpdatedTimeTill = (installation: InstallationSchema) => {
  let updatedTimeTill = installation.timeTill;
  if (installation.additionalInformation?.additionalTimeTill) {
    updatedTimeTill = installation.additionalInformation.additionalTimeTill;
  }

  return updatedTimeTill;
};

export const getBarrierDetailsCustomerAddress = (
  installation: InstallationSchema
) => {
  const streetAndHouseNumber = getUpdatedStreetAndHouseNumber(installation);
  const zipCode = getUpdatedZipCode(installation);
  const city = getUpdatedCity(installation);

  const customerAddress =
    streetAndHouseNumber + ", " + `${zipCode ? `${zipCode} ` : ""}` + city;

  return customerAddress;
};

export const getBarrierDetailsCustomerAddressWithTwoSideBlocking = (
  installation: InstallationSchema
) => {
  const customerAddress = getBarrierDetailsCustomerAddress(installation);
  const updatedIsTwoSiteBlocking = getUpdatedIsTwoSiteBlocking(installation);

  const formattedCustomerAddress =
    customerAddress +
    " / " +
    (installation.additionalInformation?.additionalLengthOfBoard ??
      installation.lengthOfBoard.split(" ")[0]) +
    (updatedIsTwoSiteBlocking ? ` (${t("orders:onBothSides")})` : "");

  return formattedCustomerAddress;
};

export function getBarrierDetailsHalteverbot24(
  installation: InstallationSchema
) {
  const customerAddress = getBarrierDetailsCustomerAddress(installation);
  return installation.barrierDetails?.halteverbot24 ?? customerAddress;
}

export function getBarrierDetailsCity(installation: InstallationSchema) {
  const customerAddress = getBarrierDetailsCustomerAddress(installation);
  return installation.barrierDetails?.city ?? customerAddress;
}

export function getIsWithPermission(installation: InstallationSchema) {
  let withPermission = installation.isWithPermission;
  if (installation.additionalInformation?.additionalIsWithPermission) {
    withPermission =
      installation.additionalInformation.additionalIsWithPermission ===
      GERMAN_YES;
  }

  return withPermission;
}
