import { Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import { debounce } from "lodash";
import { useBrandList } from "hooks/queries/useBrand";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddCarBrand from "./AddCarBrand";

type FilterParams = {
  search: string;
};

function Index() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    search: "",
  });

  const { data, isLoading } = useBrandList();
  const { t } = useTranslation();

  const filteredData = data?.filter((item) =>
    item.name.toLowerCase().includes(filterParams.search.toLowerCase())
  );

  const columns = [
    {
      title: t("carList:carBrand"),
      dataIndex: "name",
    },
  ];

  const getSearchKeywordsDeb = debounce((value: string) => {
    setFilterParams({ search: value });
  }, 500);

  if (isLoading) return <Spin />;

  return (
    <React.Fragment>
      <AntdTablePro
        columns={columns}
        rowKey="id"
        dataSource={filteredData}
        toolBarRender={[<AddCarBrand key={1} />]}
        inputElement={{
          placeholder: t("main:search"),
          handleChange: (e) => getSearchKeywordsDeb(e.target.value),
          handleSearch: (value) => setFilterParams({ search: value }),
        }}
      />
    </React.Fragment>
  );
}

export default Index;
