import { message } from "antd";
import { useInvoicePaymentNotice } from "hooks/queries/useInvoice";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import Notice from "../Notice";

export default function PaymentNotice({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useInvoicePaymentNotice(installation.id);

  const onSubmit = (data: string | null) => {
    mutate(
      { paymentNotice: data },
      {
        onSuccess: () => {
          message.success(t("general:updatedSuccess"));
        },
        onError: () => {
          message.error(t("general:updateFail"));
        },
      }
    );
  };

  return (
    <Notice
      name="paymentNotice"
      onSubmit={onSubmit}
      isLoading={isLoading}
      defaultValue={installation.paymentNotice}
    />
  );
}
