import { Divider, Form, Spin } from "antd";
import { useWorkerFindOne, useWorkerUpdate } from "hooks/queries/useWorker";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";
import { cleanData } from "utils/global";
import WorkerForm from "../WorkerForm";

export default function EditWorker() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [file, setFile] = useState<File | null>(null);

  const { data: workerDetails, isLoading: workerDetailsLoading } =
    useWorkerFindOne(params.id);

  const { mutate, isLoading } = useWorkerUpdate(params.id);

  const onSubmit = async (data) => {
    const cleanedData = cleanData(data);

    const formData = new FormData();
    Object.keys(cleanedData).forEach((key) => {
      formData.append(key, cleanedData[key]);
    });

    if (file) {
      formData.append("file", file);
    }

    mutate(formData, {
      onSuccess: () => {
        history.push("/worker/all");
      },
    });
  };

  if (workerDetailsLoading) {
    return <Spin />;
  }

  if (!workerDetails) {
    return <div>{t("warnings:error")}</div>;
  }

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
    >
      <h5>
        <Link to="/worker/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("worker:editWorker")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <WorkerForm
        worker={workerDetails}
        isEdit={true}
        isLoading={isLoading}
        file={file}
        setFile={setFile}
      />
    </Form>
  );
}
