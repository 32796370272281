import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import { useShowInputContext } from "context/ShowInputContext";
import InvoiceLabelValue from "./InvoiceLabelValue";

export default function InvoiceTogglingInput({
  name,
  label,
  placeholder,
  initialValue,
  rules,
  className,
  inputType,
}: {
  name: string;
  label: string;
  placeholder?: string;
  initialValue: string | null;
  rules?: Rule[];
  className?: string;
  inputType?: string;
}) {
  const { showInput, isLoading } = useShowInputContext();

  return (
    <div>
      <InvoiceLabelValue
        label={label}
        initialValue={initialValue}
        className={className}
        showInput={showInput}
      />
      {showInput && (
        <Form.Item name={name} initialValue={initialValue} rules={rules}>
          <Input
            type={inputType}
            disabled={isLoading}
            placeholder={placeholder}
          />
        </Form.Item>
      )}
    </div>
  );
}
