import { Button, Col, Form, Input, Row, Select } from "antd";
import { useBrandList } from "hooks/queries/useBrand";
import { useParkedCarCreate } from "hooks/queries/useParkedCar";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { colorOptionsData } from "utils/global";

interface OptionType {
  label: string;
  value: string;
}

export type ParkedCarFormData = {
  brand: string;
  carColor: string;
  licensePlate: string;
  installation?: string;
};
export default function WorkerParkedCar({
  installationId,
}: {
  installationId: string;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm<ParkedCarFormData>();

  const colorOptions = colorOptionsData.sort().map((color) => ({
    label: color,
    value: color, // Use the color itself as the value
  }));

  const { mutateAsync: postParkedCarData } = useParkedCarCreate(installationId);

  const onFinish = (data: ParkedCarFormData) => {
    const formData = {
      ...data,
      installation: installationId,
    };
    form.resetFields();
    postParkedCarData(formData);
  };

  return (
    <div>
      <h5 className="underline">{t("parkedCar:parkedCars")}:</h5>
      <Form
        onFinish={onFinish}
        layout="vertical"
        name="workerParkedCarForm"
        form={form}
      >
        <Row style={{ justifyContent: "space-between", rowGap: "1rem" }}>
          <Col lg={6} xs={24} md={8}>
            <Form.Item
              name="licensePlate"
              className="m-0"
              rules={[
                {
                  required: true,
                  message: "Please enter License plate number!",
                },
              ]}
            >
              <Input placeholder={t("parkedCar:licensePlate")} />
            </Form.Item>
          </Col>
          <Col lg={7} xs={24} md={8}>
            <CarBrandDropDown />
          </Col>
          <Col lg={7} xs={24} md={7}>
            <Form.Item
              name="carColor"
              className="m-0"
              rules={[
                {
                  required: true,
                  message: "Please enter car color!",
                },
              ]}
            >
              <Select
                options={colorOptions}
                showSearch
                placeholder={t("parkedCar:carColor")}
                filterOption={true}
              />
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Button type="primary" size="small" htmlType="submit">
              {t("general:submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

function CarBrandDropDown() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const { data } = useBrandList();

  const filteredBrandOption: OptionType[] = useMemo(() => {
    const brandOption: OptionType[] = (data || []).map((brand) => ({
      label: brand.name,
      value: brand.id,
    }));

    return brandOption.filter((option) =>
      option.label.toLowerCase().includes(search.trim().toLowerCase())
    );
  }, [data, search]);

  return (
    <Form.Item
      name="brand"
      className="m-0"
      rules={[
        {
          required: true,
          message: "Please select car brand!",
        },
      ]}
    >
      <Select
        showSearch
        placeholder={t("parkedCar:selectCarBrand")}
        filterOption={false}
        options={filteredBrandOption}
        onSearch={(v) => setSearch(v)}
      />
    </Form.Item>
  );
}
