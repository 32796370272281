import { Image, Select } from "antd";
import { useInstructionList } from "hooks/queries/useInstruction";
import { useInstallationWorkerInformationUpdate } from "hooks/queries/useInstallation";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";

export default function Instruction({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { data: instructionList, isLoading: isListLoading } =
    useInstructionList();

  const { mutate, isLoading: isUpdateLoading } =
    useInstallationWorkerInformationUpdate(installation.id);

  const onChange = (value) => {
    mutate({
      workerInstructionNumber: value,
    });
  };

  const { workerInstructionNumber } = installation;

  const instructionOptions = instructionList?.map((item) => ({
    value: item.id,
    label: t(`direction:${item.name}`),
  }));

  return (
    <div>
      <h5 className="underline">{t("general:instructions")}:</h5>
      <div className="grid grid-cols-2 gap-4">
        <Select
          options={instructionOptions}
          onChange={onChange}
          value={workerInstructionNumber ?? undefined}
          defaultValue={workerInstructionNumber ?? undefined}
          disabled={isListLoading || isUpdateLoading}
        />

        {workerInstructionNumber && (
          <Image
            src={`/img/instruction/${workerInstructionNumber}.jpg`}
            alt={`${t("general:instructions")} ${workerInstructionNumber}`}
            height={200}
            style={{
              objectFit: "contain",
            }}
          />
        )}
      </div>
    </div>
  );
}
