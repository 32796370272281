import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import { useStatusWiseCount } from "hooks/queries/useStatusWiseCount";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Role } from "types";
import { checkRoleExists } from "utils/global";
import { menuData } from "utils/global/menu";

const SideNav = React.memo((props: any) => {
  const { onlyIcons, firstSegement } = props;
  const [menuList, setMenuList] = useState<any>([]);
  const [activeData, setActiveData] = useState<any>({});
  const { t } = useTranslation();

  const { data } = useStatusWiseCount();

  useEffect(() => {
    if (!isEmpty(menuData?.items)) {
      const menuArray = menuData?.items;
      const menuD = menuArray
        ?.filter((parent) => !parent.parentId)
        .sort((a, b) => (+a.orderNumber > +b.orderNumber ? 1 : -1))
        .filter((menu) => menu.group === "sidebar")
        .filter((menu) => checkRoleExists(menu.visibleFor as Role[]))
        .map((parent) => ({
          title: parent.title,
          value: parent.id,
          icon: parent.icon,
          url: parent.url,
          slug: parent.slug,
          sidebarCountData: parent.sidebarCountData
            ? data && data[parent.sidebarCountData]
            : "",
          children: menuArray
            ?.filter((child) => +(child.parentId ?? 0) === +parent.id)
            .sort((a, b) => (+a.orderNumber > +b.orderNumber ? 1 : -1))
            .map((child) => ({
              title: child?.title,
              value: child.id,
              icon: child.icon,
              slug: child.slug,
              url: child.url,
            })),
        }));
      setMenuList(menuD);
    } else {
      setMenuList([]);
    }
  }, [menuData, data]);

  const handleDropdown = (slug: string) => {
    setActiveData({
      ...activeData,
      [slug]: !activeData[slug],
    });
  };

  const renderMenuComponent = (item) => {
    const handleActiveLink = (_match, location) => {
      const itemBasePath = item.url.split("/")[1];
      const locationBasePath = location.pathname.split("/")[1];
      return itemBasePath === locationBasePath;
    };

    return (
      <NavLink to={item.url} isActive={handleActiveLink}>
        {item.icon && (
          <Tooltip placement="right" title={item.title}>
            {item.icon}
          </Tooltip>
        )}
        <span>{t("menu:" + item.title)}</span>
        <span className="sidebar-count">
          {item.sidebarCountData ? `(${item.sidebarCountData})` : null}
        </span>
      </NavLink>
    );
  };

  useEffect(() => {
    if (!isEmpty(firstSegement) && !isEmpty(menuData?.items)) {
      const find: any = menuData?.items.find(
        (item) => item.url === firstSegement
      );
      setActiveData({
        ...activeData,
        [find?.slug]: true,
      });
    }
  }, [firstSegement]);
  return (
    <div className={`side-nav ${onlyIcons ? "icon-only" : ""}`}>
      {/* {!isEmpty(menuList) ? ( */}
      <ul className="">
        {menuList?.map((item, index) => {
          return isEmpty(item?.children) ? (
            <li key={index}>{renderMenuComponent(item)}</li>
          ) : (
            <li key={index}>
              <a
                role="button"
                href={void 0}
                onClick={() => handleDropdown(item.slug)}
              >
                {item.icon && (
                  <Tooltip placement="right" title={item.title}>
                    {item.icon}
                  </Tooltip>
                )}
                <span>{item.title}</span>
                <IoIosArrowDown className="angle-down" />
              </a>
              <ul
                className={`sub-menu ${
                  activeData?.[item.slug] ? "show-sub-menu" : ""
                }`}
                // className={`sub-menu ${
                // 	item?.url === firstSegement ? "show-sub-menu" : ""
                // }`}
              >
                {item.children.map((subItem, sIndex) => {
                  return <li key={sIndex}>{renderMenuComponent(subItem)}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default SideNav;
