import { Form, Select } from "antd";
import { useShowInputContext } from "context/ShowInputContext";
import { useCityList } from "hooks/queries/useCity";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { getTimeRegex, validateDate } from "utils/global";
import {
  getUpdatedIsTwoSiteBlocking,
  getUpdatedWithOutsideLift,
} from "utils/global/installationHelper";
import TogglingInput from "./TogglingInput";

type Props = {
  installation: InstallationSchema;
};

export function InstallationDetails({ installation }: Props) {
  const { t } = useTranslation();

  const {
    city: installationCity,
    purpose,
    dateRangeFrom,
    dateRangeTo,
    timeFrom,
    timeTill,
    lengthOfBoard,
    streetAndHouseNumber,
    zipCode,
    isTwoSiteBlock,
    isWithPermission,
    withOutsideLift,
    additionalInformation,
    updatedDateRangeFrom,
    updatedDateRangeTo,
  } = installation;

  const updatedWithOutsideLift = getUpdatedWithOutsideLift(installation);

  const updatedIsTwoSiteBlock = getUpdatedIsTwoSiteBlocking(installation);

  return (
    <div className="flex-1">
      <h5 className="underline">{t("orders:shutOffLocation")}:</h5>
      <TogglingInput
        name="additionalPurpose"
        label={t("orders:purpose")}
        originalValue={t(`orders:purposes:${purpose}`)}
        updatedValue={additionalInformation?.additionalPurpose}
      />

      <TogglingInput
        name="updatedDateRangeFrom"
        label={t("orders:dateRangeFrom")}
        originalValue={dateRangeFrom}
        updatedValue={updatedDateRangeFrom}
        placeholder="YYYY-MM-DD"
        rules={[
          {
            validator: validateDate,
          },
        ]}
      />

      <TogglingInput
        name="updatedDateRangeTo"
        label={t("orders:dateRangeTo")}
        originalValue={dateRangeTo}
        updatedValue={updatedDateRangeTo}
        placeholder="YYYY-MM-DD"
        rules={[
          {
            validator: validateDate,
          },
        ]}
      />

      <TogglingInput
        name="additionalTimeFrom"
        label={t("orders:timeFrom")}
        originalValue={timeFrom}
        updatedValue={additionalInformation?.additionalTimeFrom}
        placeholder="HH:MM"
        rules={[
          {
            pattern: getTimeRegex(),
            message: t("warnings:invalidValue"),
          },
        ]}
      />

      <TogglingInput
        name="additionalTimeTill"
        label={t("orders:timeTill")}
        originalValue={timeTill}
        updatedValue={additionalInformation?.additionalTimeTill}
        placeholder="HH:MM"
        rules={[
          {
            pattern: getTimeRegex(),
            message: t("warnings:invalidValue"),
          },
        ]}
      />

      <TogglingInput
        name="additionalLengthOfBoard"
        label={t("orders:lengthOfBoard")}
        originalValue={lengthOfBoard}
        updatedValue={additionalInformation?.additionalLengthOfBoard}
      />

      <TogglingInput
        name="additionalStreetAndHouseNumber"
        label={t("orders:streetAndHouseNumber")}
        originalValue={streetAndHouseNumber}
        updatedValue={additionalInformation?.additionalStreetAndHouseNumber}
      />

      <TogglingInput
        name="additionalZipCode"
        label={t("orders:zipCode")}
        originalValue={zipCode}
        updatedValue={additionalInformation?.additionalZipCode}
      />

      <CitySelect
        originalValue={installationCity.name}
        updatedValue={additionalInformation?.additionalCity}
      />

      <TogglingInput
        name="additionalWithOutsideLift"
        label={t("orders:withOutsideLift")}
        originalValue={withOutsideLift ? t("orders:yes") : t("orders:no")}
        updatedValue={additionalInformation?.additionalWithOutsideLift}
        className={updatedWithOutsideLift ? "text-red font-700" : undefined}
      />

      <TogglingInput
        name="additionalIsTwoSiteBlock"
        label={t("orders:isTwoSiteBlock")}
        originalValue={isTwoSiteBlock ? t("orders:yes") : t("orders:no")}
        updatedValue={additionalInformation?.additionalIsTwoSiteBlock}
        className={updatedIsTwoSiteBlock ? "text-red font-700" : undefined}
      />

      <TogglingInput
        name="additionalIsWithPermission"
        label={t("orders:isWithPermission")}
        originalValue={isWithPermission ? t("orders:yes") : t("orders:no")}
        updatedValue={additionalInformation?.additionalIsWithPermission}
      />

      <TogglingInput
        name="commentHVZ"
        label={t("orders:commentHVZ")}
        originalValue={
          additionalInformation?.commentHVZ
            ? additionalInformation?.commentHVZ
            : ""
        }
        updatedValue={additionalInformation?.commentHVZ}
      />
    </div>
  );
}

function CitySelect({
  originalValue,
  updatedValue,
}: {
  originalValue: string;
  updatedValue: string | undefined;
}) {
  const { t } = useTranslation();

  const { showInput, isLoading } = useShowInputContext();

  const { data } = useCityList();

  const cityOptions = useMemo(() => {
    const options = data?.map((city) => ({
      value: city.name,
      label: city.name,
    }));

    options?.unshift({
      label: t("general:none"),
      value: "",
    });

    return options;
  }, [data]);

  return (
    <div>
      <div>
        <b>{t("orders:city")}: </b>
        <p>
          {originalValue}&nbsp;
          {updatedValue && originalValue !== updatedValue && (
            <span className="italic text-red">({updatedValue})</span>
          )}
        </p>
      </div>
      {showInput && (
        <Form.Item name="additionalCity" initialValue={updatedValue}>
          <Select disabled={isLoading} showSearch options={cityOptions} />
        </Form.Item>
      )}
    </div>
  );
}
