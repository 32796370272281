import { Button, Col, ColProps, Form, Input, message, Row } from "antd";
import { useInvoiceSendEmail } from "hooks/queries/useInvoice";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import {
  ELIAS_ORDER_EMAIL_ADDRESS,
  FEE_INVOICE_ELIAS_EMAIL_ADDRESS,
  FEE_INVOICE_HALTEVERBOT24_EMAIL_ADDRESS,
} from "utils/global/constants";

type SendInvoiceEmailForm = {
  email: string;
};

const colStyles: ColProps = {
  xs: 24,
  md: 24,
};

export default function SendInvoiceEmail({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm<SendInvoiceEmailForm>();

  const { mutate, isLoading: isConnectLoading } = useInvoiceSendEmail(
    installation.id,
    installation.invoice!.id // This components only renders if there is an invoice
  );

  const onSubmit = async (data: SendInvoiceEmailForm) => {
    if (!data.email) return;
    mutate(data, {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
        form.setFieldsValue({
          email: "",
        });
      },
    });
  };

  const defaultEmail =
    installation.customer.email === ELIAS_ORDER_EMAIL_ADDRESS
      ? FEE_INVOICE_ELIAS_EMAIL_ADDRESS
      : FEE_INVOICE_HALTEVERBOT24_EMAIL_ADDRESS;

  return (
    <Form
      form={form}
      validateTrigger="onChange"
      onFinish={onSubmit}
      autoComplete="off"
    >
      <div className="flex flex-col">
        <h5 className="underline">{t("email:email")}:</h5>
        <Row gutter={16}>
          <Col {...colStyles}>
            <Form.Item
              name="email"
              initialValue={defaultEmail}
              rules={[
                {
                  required: true,
                  message: t("warnings:required"),
                },
                {
                  type: "email",
                  message: t("warnings:invalidValue"),
                },
              ]}
            >
              <Input placeholder={t("email:email")} />
            </Form.Item>
          </Col>
          <Col {...colStyles} style={{ paddingBottom: "16px" }}>
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={isConnectLoading}
            >
              {t("email:sendEmail")}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
}
