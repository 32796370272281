import { QuestionCircleOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-table";
import { Button, Popconfirm, Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import { debounce } from "lodash";
import {
  useAuthorityDelete,
  useAuthorityListPaginated,
} from "hooks/queries/useAuthority";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiPlus, BiShow, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Authority } from "types/authority";
import AuthorityModal from "./AuthorityModal";

type ModalState =
  | {
      isOpen: false;
      value: null;
    }
  | {
      isOpen: true;
      value: Authority;
    };

type FilterParams = {
  page: number;
  size: number;
  search: string;
  sortBy: "time";
};

function Index() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: 100,
    search: "",
    sortBy: "time",
  });

  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    value: null,
  });

  const { isLoading, data } = useAuthorityListPaginated(filterParams);

  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      title: t("authority:field.name"),
      dataIndex: "name",
    },
    {
      title: t("authority:field.address"),
      dataIndex: "address",
    },
    {
      title: t("authority:field.contactPerson"),
      dataIndex: "contactPerson",
    },
    {
      title: t("authority:field.phone"),
      dataIndex: "phone",
    },
    {
      title: t("authority:field.fax"),
      dataIndex: "fax",
    },
    {
      title: t("authority:field.email"),
      dataIndex: "email",
    },
    {
      title: t("general:action"),
      render: (_, row: Authority) => (
        <div className="flex gap-2">
          <Button
            type="primary"
            onClick={() => {
              setModalState({
                isOpen: true,
                value: row,
              });
            }}
          >
            <BiShow />
          </Button>
          <Link
            key="editLink"
            to={`/authority/edit/${row.id}`}
            className="ant-btn ant-btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              background: "#ffb630",
            }}
          >
            <BiPencil />
          </Link>
          <DeleteButton authority={row} />
        </div>
      ),
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, page: 1, search: value });
  }, 500);

  return (
    <React.Fragment>
      <AntdTablePro
        columns={columns}
        rowKey="id"
        dataSource={data?.items}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          current: filterParams?.page,
          pageSize: filterParams?.size,
          total: data?.count,
          onChange: (page, pageSize) => {
            setFilterParams({
              ...filterParams,
              page: page,
              size: pageSize,
            });
          },
        }}
        toolBarRender={[
          <Link
            key="addLink"
            to="/authority/add"
            className="ant-btn ant-btn-primary header-add-link"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="icon">
              <BiPlus />
            </span>
            <span className="text">{t("authority:addAuthority")}</span>
          </Link>,
        ]}
        inputElement={{
          placeholder: t("main:search"),
          handleChange: (e) => getSearchKeywordsDeb(e.target.value),
          handleSearch: (value) =>
            setFilterParams({ ...filterParams, search: value }),
        }}
      />
      {modalState.isOpen && (
        <AuthorityModal
          visible={modalState.isOpen}
          defaultValue={modalState.value}
          modalText={modalState.value.name}
          onHide={() => setModalState({ isOpen: false, value: null })}
        />
      )}
    </React.Fragment>
  );
}

export default Index;

function DeleteButton({ authority }: { authority: Authority }) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useAuthorityDelete(authority.id);

  return (
    <Popconfirm
      placement="leftBottom"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      title={t("general:confirmDocumentDelete")}
      onConfirm={() => mutate()}
      okText={t("general:yes")}
      cancelText={t("general:no")}
    >
      <Button type="primary" style={{ background: "red" }} loading={isLoading}>
        <BiTrash />
      </Button>
    </Popconfirm>
  );
}
