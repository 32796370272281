import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";
import { analytics } from "utils/constant/queryKey";

const END_POINT = "/analytics";

export const useAnalyticsEventCount = (
  startDate: string,
  endDate: string,
  eventType: string,
  targetName: string
) => {
  return useQuery({
    queryKey: analytics.log(startDate, endDate, eventType, targetName),
    queryFn: async () => {
      const params = new URLSearchParams({
        startDate,
        endDate,
      });

      const res = await new AuthorizedService().get(
        `${END_POINT}/${targetName}/${eventType}?${params.toString()}`
      );
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
