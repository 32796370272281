import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { Attachment } from "types/attachment";
import { isAdmin, isWorker } from "utils/global";

const GET_WORKER_ATTACHMENTS_QUERY_KEY = "worker-attachemnt-list";

const getEndPoint = (installationId: string) => {
  if (isAdmin()) {
    return `/installation/${installationId}/worker-attachment`;
  }
  if (isWorker()) {
    return `/worker-installation/${installationId}/attachment`;
  }
  return "";
};

export const useWorkerFileUpload = (installationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: FormData) => {
      await new AuthorizedService().post(getEndPoint(installationId), data);
    },
    onSuccess: () => {
      message.success(t("upload:uploadSuccess"));
    },
    onError: () => {
      message.error(t("upload:uploadFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        GET_WORKER_ATTACHMENTS_QUERY_KEY,
        installationId,
      ]);
    },
  });
};

export const useWorkerFileList = (installationId: string) => {
  return useQuery<Attachment[]>({
    queryKey: [GET_WORKER_ATTACHMENTS_QUERY_KEY, installationId],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        getEndPoint(installationId)
      );
      return res.data;
    },
  });
};

export const useWorkerFileDelete = (installationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileId: string) => {
      await new AuthorizedService().delete(
        `${getEndPoint(installationId)}/${fileId}`
      );
    },
    onSuccess: () => {
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        GET_WORKER_ATTACHMENTS_QUERY_KEY,
        installationId,
      ]);
    },
  });
};
