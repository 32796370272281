import { Button, Checkbox, Form, Input, message } from "antd";
import { useInvoicePay } from "hooks/queries/useInvoice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { InstallationSchema } from "types/installation";
import {
  formatDateYYYYMMDD,
  getPaymentMethodDescription,
  validateDate,
} from "utils/global";
import { LabelValue } from "../TogglingInput";

type PaymentStatusForm = {
  invoicePaidDateTime: string;
};

export default function PaymentStatus({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [checked, setChecked] = useState(!!installation.invoicePaid);

  const [form] = Form.useForm<PaymentStatusForm>();

  const { mutate, isLoading } = useInvoicePay(installation.id);

  const onCheckChange = () => {
    setChecked((c) => !c);
    form.setFields([
      {
        name: "invoicePaidDateTime",
        errors: undefined,
      },
    ]);
  };

  const onSuccess = () => {
    setEditMode(false);
    message.success(t("general:updatedSuccess"));
  };

  const onError = () => {
    message.error(t("general:updateFail"));
  };

  const onSubmit = (v: PaymentStatusForm) => {
    if (!checked) {
      mutate(
        {
          invoicePaid: false,
        },
        {
          onSuccess,
          onError,
        }
      );
      return;
    }

    if (!v.invoicePaidDateTime) {
      form.setFields([
        {
          name: "invoicePaidDateTime",
          errors: [t("warnings:required")],
        },
      ]);
      return;
    }

    mutate(
      {
        invoicePaid: true,
        invoicePaidDateTime: v.invoicePaidDateTime,
      },
      {
        onSuccess,
        onError,
      }
    );
  };

  const value = installation.invoicePaidDateTime
    ? formatDateYYYYMMDD(installation.invoicePaidDateTime)
    : "";

  return (
    <Form
      form={form}
      validateTrigger="onChange"
      onFinish={onSubmit}
      autoComplete="off"
    >
      <div>
        <div className="flex justify-between items-center gap-2">
          <h5 className="underline">{t("orders:paymentStatus:title")}:</h5>
          {editMode ? (
            <div className="flex gap-2">
              <Button
                key="save"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <BiSave />
              </Button>
              <Button
                key="cancel"
                type="default"
                className="btn-outline"
                onClick={() => setEditMode(false)}
              >
                <RxCross2 />
              </Button>
            </div>
          ) : (
            <Button
              key="edit"
              type="primary"
              htmlType="button"
              onClick={() => setEditMode(true)}
            >
              <BiPencil />
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div>
            {getPaymentMethodDescription(installation.payment.paymentType)}
          </div>
          <div className="grid grid-cols-2">
            <Checkbox
              checked={checked}
              disabled={!editMode || isLoading}
              onChange={onCheckChange}
            >
              {t("orders:paymentStatus:paid")}
            </Checkbox>
            {editMode ? (
              <Form.Item
                name="invoicePaidDateTime"
                initialValue={value}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      return validateDate(_, value);
                    },
                  },
                ]}
              >
                <Input
                  disabled={!checked || isLoading}
                  placeholder="YYYY-MM-DD"
                />
              </Form.Item>
            ) : (
              <LabelValue
                label={t("orders:paymentStatus:paidDate")}
                originalValue={value || "-"}
              />
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
