import Upload from "components/reusable/Upload";
import {
  useWorkerFileDelete,
  useWorkerFileList,
  useWorkerFileUpload,
} from "hooks/queries/useWorkerAttachment";
import { Attachment } from "types/attachment";

type WorkerUploadProps = {
  installationId: string;
  maxFiles?: number;
};

export default function WorkerUpload({
  installationId,
  maxFiles,
}: WorkerUploadProps) {
  const { mutateAsync: uploadFile, isLoading } =
    useWorkerFileUpload(installationId);

  const { data } = useWorkerFileList(installationId);

  const { mutate: deleteFile } = useWorkerFileDelete(installationId);

  const handleDelete = (file: Attachment) => {
    deleteFile(file.id);
  };

  return (
    <Upload
      handleDelete={handleDelete}
      attachmentList={data}
      uploadFile={uploadFile}
      isLoading={isLoading}
      maxFiles={maxFiles}
    />
  );
}
