import { Form } from "antd";
import generatePicker from "antd/lib/date-picker/generatePicker";
import { Rule } from "antd/lib/form";
import { useShowInputContext } from "context/ShowInputContext";
import dayjsGenerateConfig from "rc-picker/lib/generate/dateFns";
import InvoiceLabelValue from "./InvoiceLabelValue";

const DatePicker = generatePicker<Date>(dayjsGenerateConfig);

export default function InvoiceTogglingDatePicker({
  name,
  label,
  placeholder,
  initialValue,
  rules,
  className,
}: {
  name: string;
  label: string;
  placeholder?: string;
  initialValue: string | null;
  rules?: Rule[];
  className?: string;
}) {
  const { showInput, isLoading } = useShowInputContext();

  return (
    <div>
      <InvoiceLabelValue
        label={label}
        initialValue={initialValue}
        className={className}
        showInput={showInput}
      />
      {showInput && (
        <Form.Item
          name={name}
          initialValue={initialValue ? new Date(initialValue) : null}
          rules={rules}
        >
          <DatePicker
            allowClear={false}
            disabled={isLoading}
            placeholder={placeholder}
          />
        </Form.Item>
      )}
    </div>
  );
}
