import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import { CompanyUser } from "types/companyUser";
import { companyUserKeys } from "utils/constant/queryKey";

const END_POINT = "/company-user";

export const useCompanyUserListPaginated = (params) => {
  return useQuery<PaginatedResponse<CompanyUser[]>>({
    queryKey: companyUserKeys.listPaginated(params),
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
  });
};

export const useCompanyUserFindOne = (companyUserId: string) => {
  return useQuery<CompanyUser>({
    queryKey: companyUserKeys.find(companyUserId),
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        `${END_POINT}/${companyUserId}`
      );
      return res.data;
    },
  });
};

export const useCompanyUserCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FormData) => {
      return new AuthorizedService().post(END_POINT, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyUserKeys.all);
    },
  });
};

export const useCompanyUserUpdate = (companyUserId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FormData) => {
      return new AuthorizedService().put(`${END_POINT}/${companyUserId}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyUserKeys.all);
    },
  });
};
