import { Divider, Form, FormInstance, message } from "antd";
import { useCompanyUserCreate } from "hooks/queries/useCompanyUser";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import CompanyUserForm from "../CompanyUserForm";

export default function CompanyUserCreatePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const { mutate, isLoading } = useCompanyUserCreate();

  const onSubmit = async (data) => {
    mutate(data, {
      onSuccess: () => {
        message.success(t("companyUser:messages.createdSuccessfully"));
        history.push("/company-user/all");
      },
      onError: (e: any) => {
        if (e.status === 409) {
          if (e?.data?.message?.email) {
            formRef.current?.setFields([
              {
                name: "email",
                errors: [t("warnings:emailTaken")],
              },
            ]);
          }
          if (e?.data?.message?.code) {
            formRef.current?.setFields([
              {
                name: "code",
                errors: [t("warnings:codeTaken")],
              },
            ]);
          }
        } else {
          message.error(t("companyUser:messages.creationFailed"));
        }
      },
    });
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
      ref={formRef}
    >
      <h5>
        <Link to="/company-user/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("companyUser:addCompanyUser")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <CompanyUserForm isLoading={isLoading} />
    </Form>
  );
}
