import { Divider, Form, message } from "antd";
import { useCityCreate } from "hooks/queries/useCity";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import { CityFormBody } from "types/city";
import CityForm from "../CityForm";

export default function AddCity() {
  const { t } = useTranslation();
  const history = useHistory();

  const { mutate, isLoading } = useCityCreate();

  const onSubmit = async (data: CityFormBody) => {
    mutate(data, {
      onSuccess: () => {
        message.success(t("cities:messages.createdSuccessfully"));
        history.push("/city/all");
      },
      onError: () => {
        message.error(t("cities:messages.creationFailed"));
      },
    });
  };

  return (
    <Form
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
    >
      <h5>
        <Link to="/city/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("cities:addCity")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <CityForm isLoading={isLoading} />
    </Form>
  );
}
