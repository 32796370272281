import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";

export const CUSTOMER_LOGIN_LOG_QUERY_KEY = "customer-login-log";
const END_POINT = "/installation";

export const useCustomerLoginLogListPaginated = (
  installationId: string,
  params: { page: number; size: number }
) => {
  return useQuery<PaginatedResponse<unknown>>({
    queryKey: [CUSTOMER_LOGIN_LOG_QUERY_KEY, installationId, params],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        `${END_POINT}/${installationId}/customer-login-log`,
        params
      );
      return res.data;
    },
  });
};
