import { Collapse } from "antd";
import OrderTable from "components/orders/OrderTable";
import CollapsePanelHeader from "components/reusable/CollapsePanelHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ELIAS_ORDER_EMAIL_ADDRESS } from "utils/global/constants";

const FILTER_SETTINGS = {
  applyForPermit: {
    acceptOrder: true,
    rejectOrder: false,
    requestApproval: false,
    permitGranted: false,
    closeOrder: false,
  },
  permitsNotGranted: {
    acceptOrder: true,
    rejectOrder: false,
    requestApproval: true,
    permitGranted: false,
    closeOrder: false,
  },
  erectSigns: {
    acceptOrder: true,
    rejectOrder: false,
    erectSigns: false,
    collectSigns: false,
    closeOrder: false,
  },
  drawUpInstallationProtocol: {
    acceptOrder: true,
    rejectOrder: false,
    erectSigns: true,
    installationProtocol: false,
    sendDocumentsToCustomer: false,
    collectSigns: false,
    closeOrder: false,
  },
  sendDocumentsToCustomer: {
    acceptOrder: true,
    rejectOrder: false,
    installationProtocol: true,
    sendDocumentsToCustomer: false,
    collectSigns: false,
    closeOrder: false,
  },
  collectSigns: {
    acceptOrder: true,
    rejectOrder: false,
    erectSigns: true,
    collectSigns: false,
    closeOrder: false,
  },
  sendInvoice: {
    acceptOrder: true,
    rejectOrder: false,
    sendInvoice: false,
    closeOrder: false,
  },
  invoiceOpen: {
    acceptOrder: true,
    rejectOrder: false,
    sendInvoice: true,
    invoicePaid: false,
    closeOrder: false,
  },
  invoicePaid: {
    acceptOrder: true,
    rejectOrder: false,
    invoicePaid: true,
    closeOrder: false,
  },
};

const Index = () => {
  const { t } = useTranslation();

  const [totalNumbers, setTotalNumbers] = useState({});

  // Callback function to update totalNumbers state
  const handleTotalDataChange = (key, count) => {
    setTotalNumbers((prevTotalNumbers) => ({
      ...prevTotalNumbers,
      [key]: count,
    }));
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        {Object.entries(FILTER_SETTINGS).map(([key, filterSettings]) => (
          <Collapse key={key}>
            <Collapse.Panel
              header={
                <CollapsePanelHeader
                  textKey={t(`task:tableHeadings.${key}`)}
                  totalNumber={totalNumbers[key]}
                />
              }
              forceRender
              key={key}
            >
              <OrderTable
                {...(key === "erectSigns" && { orderBy: "setupDate" })}
                {...(key === "collectSigns" && { orderBy: "pickupDate" })}
                {...((key === "sendInvoice" || key === "invoiceOpen") && {
                  excludeEmail: ELIAS_ORDER_EMAIL_ADDRESS,
                })}
                {...((key === "applyForPermit" ||
                  key === "permitsNotGranted") && {
                  withPermission: true,
                })}
                {...((key === "erectSigns" || key === "collectSigns") && {
                  // In setup and pickup tasks table, hide order with `noSignInstallation` true
                  noSignInstallation: false,
                })}
                statusFilter={filterSettings}
                onTotalDataChange={(count) => handleTotalDataChange(key, count)}
                hasAll={true}
              />
            </Collapse.Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
};

export default Index;
