import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Notice({
  name,
  defaultValue,
  onSubmit,
  isLoading,
}: {
  name: "notice" | "workerNotice" | "paymentNotice";
  defaultValue: string | null;
  onSubmit: (v: string | null) => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [value, setValue] = useState(defaultValue ?? "");

  const onFinish = () => {
    if (value === defaultValue) return;
    onSubmit(value || null);
  };

  const onBlur = () => {
    form.submit();
  };

  return (
    <div>
      <h5 className="underline">{t("orders:notice.notice")}:</h5>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={name}
          rules={[
            {
              max: 1000,
              message: t("orders:notice.maxLimitExceeded"),
            },
          ]}
          initialValue={value}
          className="m-0"
        >
          <TextArea
            style={{ height: "100px" }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            disabled={isLoading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
