import { ProColumns } from "@ant-design/pro-table";
import AntdTablePro from "components/antdTablePro";
import { useInvoiceSentEmailList } from "hooks/queries/useInvoice";
import React from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { InvoiceEmail } from "types/invoice";
import { formattedDateTime } from "utils/global";

export default function InvoiceEmailSentList({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { data } = useInvoiceSentEmailList(
    installation.id,
    installation.invoice!.id
  ); // This components only renders if there is an invoice

  const columns: ProColumns[] = [
    {
      key: "email",
      title: t("email:email"),
      render: (_, row: InvoiceEmail) => {
        return <div>{row.email}</div>;
      },
    },
    {
      key: "createdAt",
      title: t("general:sentAt"),
      render: (_, row: InvoiceEmail) => {
        return <div>{formattedDateTime(row.createdAt)}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      {data && (
        <AntdTablePro
          columns={columns}
          rowKey="id"
          dataSource={data.data}
          pagination={false}
          toolBarRender={false}
          className="mb-4"
        />
      )}
    </React.Fragment>
  );
}
