import { Divider, Form, FormInstance } from "antd";
import { useWorkerCreate } from "hooks/queries/useWorker";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import { cleanData } from "utils/global";
import WorkerForm from "../WorkerForm";

export default function AddAuthority() {
  const { t } = useTranslation();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const [file, setFile] = useState<File | null>(null);

  const { mutate, isLoading } = useWorkerCreate(formRef);

  const onSubmit = async (data) => {
    const cleanedData = cleanData(data);

    const formData = new FormData();
    Object.keys(cleanedData).forEach((key) => {
      formData.append(key, cleanedData[key]);
    });

    if (file) {
      formData.append("file", file);
    }

    mutate(formData, {
      onSuccess: () => {
        history.push("/worker/all");
      },
    });
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
      ref={formRef}
    >
      <h5>
        <Link to="/worker/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("worker:addWorker")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <WorkerForm isLoading={isLoading} file={file} setFile={setFile} />
    </Form>
  );
}
