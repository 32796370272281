import { AccountingOrder, OpenOrder, WorkerOrderList } from "pages";
import { lazily } from "react-lazily";
import { ADMIN_ROLE, ALL_ROLE, WORKER_ROLE } from "utils/global/constants";

const { Dashboard } = lazily(() => import("pages"));

const { AuthorityList } = lazily(() => import("pages"));
const { AddAuthority } = lazily(() => import("pages"));
const { EditAuthority } = lazily(() => import("pages"));

const { WorkerList } = lazily(() => import("pages"));
const { AddWorker } = lazily(() => import("pages"));
const { EditWorker } = lazily(() => import("pages"));

const { Cities } = lazily(() => import("pages"));
const { AddCity } = lazily(() => import("pages"));
const { EditCity } = lazily(() => import("pages"));

const { OrderList } = lazily(() => import("pages"));
const { TaskList } = lazily(() => import("pages"));
const { CompleteOrder } = lazily(() => import("pages"));
const { NewOrder } = lazily(() => import("pages"));
const { NewOrderWithIncludeEmail } = lazily(() => import("pages"));
const { RejectCancelOrder } = lazily(() => import("pages"));

const { Customizables } = lazily(() => import("pages"));

const { CompanyUserList } = lazily(() => import("pages"));
const { AddCompanyUser } = lazily(() => import("pages"));
const { EditCompanyUser } = lazily(() => import("pages"));

const { CarList } = lazily(() => import("pages"));

const { Analytics } = lazily(() => import("pages"));

export const privateRouteLists = [
  {
    exact: true,
    path: "/dashboard",
    title: "Dashboard",
    title_np: "Dashboard",
    component: Dashboard,
    roles: [...ALL_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/order/all",
    title: "Order",
    title_np: "Order",
    component: OrderList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/worker-order/all",
    title: "Order",
    title_np: "Order",
    component: WorkerOrderList,
    roles: [WORKER_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/task/all",
    title: "Task",
    title_np: "Task",
    component: TaskList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/worker/all",
    title: "Worker",
    title_np: "Worker",
    component: WorkerList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/worker/add",
    title: "AddWorker",
    title_np: "AddWorker",
    component: AddWorker,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/worker/edit/:id",
    title: "EditWorker",
    title_np: "EditWorker",
    component: EditWorker,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/authority/all",
    title: "Authority",
    title_np: "Authority",
    component: AuthorityList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/authority/add",
    title: "AddAuthority",
    title_np: "AddAuthority",
    component: AddAuthority,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/authority/edit/:id",
    title: "EditAuthority",
    title_np: "EditAuthority",
    component: EditAuthority,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/city/all",
    title: "Cities",
    title_np: "Cities",
    component: Cities,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/city/add",
    title: "AddCity",
    title_np: "AddCity",
    component: AddCity,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/city/edit/:id",
    title: "EditCity",
    title_np: "EditCity",
    component: EditCity,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  // {
  // 	exact: true,
  // 	path: "*",
  // 	title: "Not Found",
  // 	title_np: "Not Found",
  // 	component: PageNotFound,
  // 	roles: [...ALL_ROLE],
  // 	isAuthenticated: false,
  // },

  {
    exact: true,
    path: "/new-order",
    title: "New Order",
    title_np: "New Order",
    component: NewOrder,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/open-order",
    title: "Open Order",
    title_np: "Open Order",
    component: OpenOrder,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/accounting-order",
    title: "Accounting Order",
    title_np: "Accounting Order",
    component: AccountingOrder,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/complete-order",
    title: "CompleteOrder",
    title_np: "CompleteOrder",
    component: CompleteOrder,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/new-order-e",
    title: "New Order",
    title_np: "New Order",
    component: NewOrderWithIncludeEmail,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/reject-cancel-order",
    title: "RejectCancelOrder",
    title_np: "RejectCancelOrder",
    component: RejectCancelOrder,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/customizables",
    title: "Customizables",
    title_np: "Customizables",
    component: Customizables,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/company-user/all",
    title: "CompanyUser",
    title_np: "CompanyUser",
    component: CompanyUserList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },

  {
    exact: true,
    path: "/company-user/add",
    title: "AddCompanyUser",
    title_np: "AddCompanyUser",
    component: AddCompanyUser,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/company-user/edit/:id",
    title: "EditCompanyUser",
    title_np: "EditCompanyUser",
    component: EditCompanyUser,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/car-list/all",
    title: "CarList",
    title_np: "CarList",
    component: CarList,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
  {
    exact: true,
    path: "/analytics",
    title: "Analytics",
    title_np: "Analytics",
    component: Analytics,
    roles: [ADMIN_ROLE],
    isAuthenticated: true,
  },
];

export const publicRouteList = privateRouteLists.filter(
  (item) => !item.isAuthenticated
);
