import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { carList } from "utils/constant/queryKey";

const END_POINT = "/brand";

export type Brand = {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export const useBrandList = () => {
  return useQuery<Brand[]>({
    queryKey: carList.all,
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT);
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useBrandCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { name: string }) => {
      return new AuthorizedService().post(END_POINT, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(carList.all);
    },
  });
};
