// Pattern adopted from the following blog post:
// https://tkdodo.eu/blog/effective-react-query-keys

export const cityKeys = {
  all: ["city"] as const,
  list: () => [...cityKeys.all, "list"] as const,
  listPaginated: (params: unknown) =>
    [...cityKeys.all, "listPaginated", params] as const,
  find: (id: string) => [...cityKeys.all, "find", id] as const,
};

export const instructionKeys = {
  all: ["instruction"] as const,
  list: () => [...instructionKeys.all, "list"] as const,
};

export const workerKeys = {
  all: ["worker"] as const,
  list: () => [...workerKeys.all, "list"] as const,
  listPaginated: (params: unknown) =>
    [...workerKeys.all, "listPaginated", params] as const,
  find: (id: string) => [...workerKeys.all, "find", id] as const,
};

export const invoiceConnectionKeys = {
  all: ["invoice-connection"] as const,
  list: (installationId: string, invoiceId?: string) =>
    [...invoiceConnectionKeys.all, "list", installationId, invoiceId] as const,
};

export const invoiceSentEmailKeys = {
  all: ["invoice-sent-email"] as const,
  list: (installationId: string, invoiceId?: string) =>
    [...invoiceSentEmailKeys.all, "list", installationId, invoiceId] as const,
};

export const companyUserKeys = {
  all: ["company-user"] as const,
  listPaginated: (params: unknown) =>
    [...companyUserKeys.all, "listPaginated", params] as const,
  find: (id: string) => [...companyUserKeys.all, "find", id] as const,
};

export const carList = {
  all: ["carList"] as const,
};

export const analytics = {
  all: ["analytics"] as const,
  log: (
    startDate: string,
    endDate: string,
    eventType: string,
    targetName: string
  ) =>
    [
      ...analytics.all,
      "log",
      startDate,
      endDate,
      eventType,
      targetName,
    ] as const,
};
