export const colorOptionsData = [
  "Schwarz",
  "Weiß",
  "Silber",
  "Blau",
  "Rot",
  "Grün",
  "Gelb",
  "Grau",
  "Lila",
  "Braun",
  "Sonstiges",
  "Beige",
  "Gold",
  "Rosa",
];
