import jwt_decode from "jwt-decode";
import { authToken } from ".";
import { User } from "hooks/queries/useAuth";
import { Role } from "types";

export const checkRoleExists = (item: Role[]) => {
  const token = authToken.getToken();
  if (!token) {
    return false;
  }

  const user: User = jwt_decode(token);

  const roleArray = [user?.role];
  return roleArray ? item?.some((v) => roleArray?.indexOf(v) !== -1) : false;
};
