import React, { useRef } from "react";
import { Modal, Form, Input, Button, message, FormInstance } from "antd";
import { useBrandCreate } from "hooks/queries/useBrand";
import { useTranslation } from "react-i18next";

type CarBrandFormProps = {
  isVisible: boolean;
  onClose: () => void;
};

const CarBrandForm: React.FC<CarBrandFormProps> = ({ isVisible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { mutate, isLoading } = useBrandCreate();

  const formRef = useRef<FormInstance>(null);

  const handleAddBrand = (values) => {
    mutate(values, {
      onSuccess: () => {
        form.resetFields();
        onClose();
        message.success(t("carList:messages.createdSuccessfully"));
      },

      onError: (e: any) => {
        if (e?.status !== 400) {
          message.error(t("carList:messages.creationFailed"));
          return;
        }

        if (e?.data?.message?.name === "Brand name already exists") {
          formRef.current?.setFields([
            {
              name: "name",
              errors: [t("warnings:brandTaken")],
            },
          ]);
        }
      },
    });
  };

  return (
    <Modal
      title={t("carList:addCarBrand")}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddBrand}
        autoComplete="off"
        ref={formRef}
      >
        <Form.Item
          label={t("carList:carBrand")}
          name="name"
          rules={[{ required: true, message: t("warnings:required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("general:submit")}
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={onClose}>
            {t("general:cancel")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CarBrandForm;
