import ShowInputProvider from "context/ShowInputContext";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import Invoice from "./invoice/Invoice";
import InvoiceConnection from "./invoice/InvoiceConnection";
import InvoiceEmailSentList from "./invoice/InvoiceEmailSentList";
import SendInvoiceEmail from "./invoice/SendInvoiceEmail";
import PaymentNotice from "./PaymentNotice";
import PaymentStatus from "./PaymentStatus";

export default function PaymentTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div>
        <h5 className="underline">{t("orders:modalTabs:payment")}</h5>
        <PaymentStatus installation={installation} />
      </div>
      <div>
        <ShowInputProvider>
          <Invoice installation={installation} />
        </ShowInputProvider>
      </div>
      {installation.invoice && (
        <div>
          <SendInvoiceEmail installation={installation} />
          <InvoiceEmailSentList installation={installation} />
          <InvoiceConnection installation={installation} />
        </div>
      )}
      <div>
        <PaymentNotice installation={installation} />
      </div>
    </div>
  );
}
