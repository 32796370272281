import { ProColumns } from "@ant-design/pro-table";
import AntdTablePro from "components/antdTablePro";
import { useCustomerLoginLogListPaginated } from "hooks/queries/useCustomerLoginLog";
import { ParkedCar } from "hooks/queries/useParkedCar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formattedDateTime } from "utils/global";

type FilterParams = {
  page: number;
  size: number;
};

export default function CustomerLoginLog({
  installationId,
}: {
  installationId: string;
}) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<FilterParams>({
    page: 1,
    size: 10,
  });

  const { data } = useCustomerLoginLogListPaginated(installationId, filter);

  const columns: ProColumns[] = [
    {
      key: "createdAt",
      title: t("customerLoginLog:loggedInAt"),
      render: (_, row: ParkedCar) => {
        return <div>{formattedDateTime(row.createdAt)}</div>;
      },
    },
  ];

  return (
    <div>
      <h5 className="underline">{t("customerLoginLog:loggedInAt")}:</h5>
      <AntdTablePro
        columns={columns}
        rowKey="id"
        dataSource={data?.items}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          current: filter?.page,
          pageSize: filter?.size,
          total: data?.count,
          onChange: (page, pageSize) => {
            setFilter({
              ...filter,
              page: page,
              size: pageSize,
            });
          },
        }}
        toolBarRender={false}
      />
    </div>
  );
}
