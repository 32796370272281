import Upload from "components/reusable/Upload";
import {
  useAdminFileForCustomerStatus,
  useAdminFileUpload,
  useAdminFileDelete,
  useAdminUploadedFileList,
} from "hooks/queries/useAdminAttachment";
import { useCustomizableList } from "hooks/queries/useCustomizable";
import { useState } from "react";
import { Attachment } from "types/attachment";
import { InstallationSchema } from "types/installation";
import EmailButton from "./EmailButton";
import EmailModal from "./EmailModal";

export default function AdminUpload({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const installationId = installation.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutateAsync: uploadFile, isLoading } =
    useAdminFileUpload(installationId);

  const setModalOpenClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { data } = useAdminUploadedFileList(installationId);

  const { mutate } = useAdminFileDelete(installationId);

  const { mutateAsync: isForCustomer } = useAdminFileForCustomerStatus();

  const { data: customizables } = useCustomizableList();

  const handleDelete = (file: Attachment) => {
    mutate(file.id);
  };

  const handleChange = (file) => {
    isForCustomer(file);
  };

  return (
    <>
      <Upload
        handleDelete={handleDelete}
        attachmentList={data}
        uploadFile={uploadFile}
        isLoading={isLoading}
        forCustomerFileStatus={handleChange}
        emailSendButton={<EmailButton setModalOpenClose={setModalOpenClose} />}
      />

      {!!customizables && (
        <EmailModal
          visible={isModalOpen}
          handleCancel={setModalOpenClose}
          installation={installation}
          customizables={customizables}
        />
      )}
    </>
  );
}
