import { Switch } from "react-router-dom";
import { privateRouteLists } from "utils/constant";
import PrivateRoute from "./PrivateRoute";
import { Redirect, Route } from "react-router-dom";
import { Suspense } from "react";
import { PageNotFound, Spinner } from "components";
import { Unauthorized } from "pages";
import { useAuth } from "hooks/queries/useAuth";
import { checkRoleExists, isWorker } from "utils/global";
import { lazily } from "react-lazily";
import { Role } from "types";

const { Login } = lazily(() => import("pages"));

const MainRoute = () => {
  const { isLoggedIn } = useAuth();
  const defaultPage = isLoggedIn
    ? isWorker()
      ? "/worker-order/all"
      : "/dashboard"
    : "/login";
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to={defaultPage} />
        </Route>
        <Route exact={true} path="/unauthorized" component={Unauthorized} />
        <Suspense fallback={<Spinner />}>
          <Route exact={true} path="/login" component={Login} />
          {privateRouteLists.map(
            (
              {
                exact,
                component,
                path,
                title,
                title_np,
                isAuthenticated,
                roles,
              },
              index
            ) => {
              if (!isLoggedIn) return <Redirect to="/login" />;

              const roleCheck = checkRoleExists(roles as Role[]);
              if (!roleCheck) return null;
              return (
                <PrivateRoute
                  key={index}
                  exact={exact}
                  component={component}
                  path={path}
                  title={title}
                  title_np={title_np}
                  isAuthenticated={isAuthenticated}
                  roles={roles}
                />
              );
            }
          )}
        </Suspense>
        <Route exact={true} path="*" component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default MainRoute;
