import React from "react";
import { Button, Col, Form, Input, Row, Radio, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { CompanyUser } from "types/companyUser";

const colStyles = {
  xs: 24,
  md: 12,
};

const rowStyles = {
  gutter: 16,
};

const CODE_LENGTH = 16;

export default function CompanyUserForm({
  isLoading,
  companyUserDetails,
}: {
  isLoading: boolean;
  companyUserDetails?: CompanyUser;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.salutation")}
            name="gender"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.gender}
          >
            <Radio.Group>
              <Radio value="male">{t("general:male")}</Radio>
              <Radio value="female">{t("general:female")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.code")}
            name="code"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
              {
                len: CODE_LENGTH,
                message: t("warnings:lengthShouldBe", { length: CODE_LENGTH }),
              },
            ]}
            initialValue={companyUserDetails?.code}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.firstName")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.firstName}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.lastName")}
            name="lastName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.lastName}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.companyName")}
            name="companyName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.companyName}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
              {
                type: "email",
                message: t("warnings:invalidValue"),
              },
            ]}
            initialValue={companyUserDetails?.email}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.telephoneNumber")}
            name="telephoneNumber"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.telephoneNumber}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.cityAndZipCode")}
            name="cityAndZipCode"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.cityAndZipCode}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.streetAndHouseNumber")}
            name="streetAndHouseNumber"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={companyUserDetails?.streetAndHouseNumber}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("companyUser:field.discount")}
            name="discount"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const isValid = /^\d{1,3}(\.\d{1,2})?$/.test(value);
                  return isValid
                    ? Promise.resolve()
                    : Promise.reject(t("warnings:invalidValue"));
                },
              },
            ]}
            initialValue={companyUserDetails?.discount}
          >
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t("general:submit")}
      </Button>
    </>
  );
}
