import { Divider, Form, FormInstance, message, Spin } from "antd";
import {
  useCompanyUserFindOne,
  useCompanyUserUpdate,
} from "hooks/queries/useCompanyUser";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";
import CompanyUserForm from "../CompanyUserForm";

export default function CompanyUserEditPage() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const { data: companyUserDetails, isLoading: companyUserDetailsLoading } =
    useCompanyUserFindOne(params.id);

  const { mutate, isLoading } = useCompanyUserUpdate(params.id);

  const onSubmit = async (data) => {
    mutate(data, {
      onSuccess: () => {
        message.success(t("companyUser:messages.updatedSuccessfully"));
        history.push("/company-user/all");
      },
      onError: (e: any) => {
        if (e.status === 409) {
          if (e?.data?.message?.email) {
            formRef.current?.setFields([
              {
                name: "email",
                errors: [t("warnings:emailTaken")],
              },
            ]);
          }
          if (e?.data?.message?.code) {
            formRef.current?.setFields([
              {
                name: "code",
                errors: [t("warnings:codeTaken")],
              },
            ]);
          }
        } else {
          message.error(t("companyUser:messages.updateFailed"));
        }
      },
    });
  };

  if (companyUserDetailsLoading) {
    return <Spin />;
  }

  if (!companyUserDetails) {
    return <div>{t("warnings:error")}</div>;
  }

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
      ref={formRef}
    >
      <h5>
        <Link to="/company-user/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("companyUser:editCompanyUser")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />

      <CompanyUserForm
        isLoading={isLoading}
        companyUserDetails={companyUserDetails}
      />
    </Form>
  );
}
