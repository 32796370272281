import FileUploader from "components/reusable/FileUploader";
import UploadedListItem from "components/reusable/UploadListItem";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Attachment } from "types/attachment";

export type UploadProps = {
  uploadFile: Function;
  isLoading: boolean;
  handleDelete?: (file: Attachment) => void;
  forCustomerFileStatus?: (file) => void;
  emailSendButton?: React.ReactNode;
  maxFiles?: number;
} & (
  | {
      attachmentList: Attachment[] | undefined;
      attachmentNode?: never;
    }
  | {
      attachmentList?: never;
      attachmentNode: ReactNode;
    }
);

export default function Upload({
  handleDelete,
  attachmentList,
  uploadFile,
  isLoading,
  attachmentNode,
  forCustomerFileStatus,
  emailSendButton,
  maxFiles,
}: UploadProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center justify-between">
        <h5 className="underline">{t("upload:upload")}: </h5>
        {emailSendButton}
      </div>

      <div className="responsive-two-column">
        <FileUploader
          maxFiles={maxFiles}
          uploadFn={uploadFile}
          isLoading={isLoading}
        />
        <div className="flex flex-col gap-2">
          {attachmentNode ? (
            <>{attachmentNode}</>
          ) : (
            attachmentList?.map((file) => (
              <UploadedListItem
                key={file.id}
                file={file}
                onDelete={handleDelete}
                forCustomerFileStatus={forCustomerFileStatus}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
