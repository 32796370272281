import React, { useState } from "react";
import { Button } from "antd";
import { BiPlus } from "react-icons/bi";
import CarBrandForm from "./CarBrandForm";
import { useTranslation } from "react-i18next";

function AddCarBrand() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsModalVisible(true)}
        className="ant-btn ant-btn-primary header-add-link"
      >
        <span className="icon">
          <BiPlus />
        </span>
        <span className="text">{t("carList:addCarBrand")}</span>
      </Button>

      <CarBrandForm
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
}

export default AddCarBrand;
