import React, { useState } from "react";
import DateRangePicker from "./DateRangePicker";
import "antd/dist/antd.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BarGraph from "./BarGraph";

const Index = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [eventType] = useState("click");
  const [targetName] = useState("Button Weiter");

  const handleRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <h4> {t("analytics:analytics")}</h4>

      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onRangeChange={handleRangeChange}
      />
      <BarGraph
        startDate={startDate}
        endDate={endDate}
        eventType={eventType}
        targetName={targetName}
      />
    </div>
  );
};

export default Index;
