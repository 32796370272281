import { Button, Form, FormInstance, Input } from "antd";
import TinyMceEditor from "components/reusable/TinyMceEditor";
import { useAdminSendEmailToCustomer } from "hooks/queries/useAdminAttachment";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiMailSend } from "react-icons/bi";
import { InstallationSchema } from "types/installation";
import { stringTemplatingReplaceAll } from "utils/global";
import { getSalutationFromGender } from "utils/global/installationHelper";
import { EmailData } from "./EmailModal";

interface EmailFormProps {
  form: FormInstance<EmailData>;
  emailBody: string;
  setEmailBody: React.Dispatch<React.SetStateAction<string>>;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  handleCancel: () => void;
  installation: InstallationSchema;
  paymentId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  gender: string | undefined;
}

export default function EmailForm({
  handleCancel,
  installation,
  emailBody,
  setEmailBody,
  subject,
  setSubject,
  paymentId,
  firstName,
  lastName,
  gender,
  form,
}: EmailFormProps) {
  const { t } = useTranslation();

  const { mutate: sendEmail, isLoading } = useAdminSendEmailToCustomer({
    id: installation.id,
    form,
    handleCancel,
  });

  const onFinish = () => {
    sendEmail({
      subject,
      emailBodyText: emailBody,
    });
  };

  const replacingValues = [
    {
      pattern: paymentId,
      value: installation.payment.paymentId,
    },
    {
      pattern: firstName,
      value: installation.customer.firstName,
    },
    {
      pattern: lastName,
      value: installation.customer.lastName,
    },
    {
      pattern: gender,
      value: installation.customer.gender
        ? getSalutationFromGender(installation.customer.gender)
        : "",
    },
  ];

  const onSubjectChange = (subject: string) => {
    const value = stringTemplatingReplaceAll(subject, replacingValues);

    form.setFields([
      {
        name: "subject",
        errors: undefined,
        value: value,
      },
    ]);
    setSubject(value);
  };

  const onBodyChange = (email: string) => {
    const value = stringTemplatingReplaceAll(email, replacingValues);
    form.setFields([
      {
        name: "emailBodyText",
        errors: undefined,
        value: value,
      },
    ]);
    setEmailBody(value);
  };

  return (
    <Form onFinish={onFinish} layout="vertical" form={form}>
      <Form.Item
        shouldUpdate={false}
        name="subject"
        label={t("email:subject")}
        rules={[
          {
            required: true,
            message: `${t("email:subjectValidation")}`,
          },
        ]}
        initialValue={subject}
      >
        <div>
          <Input
            value={subject}
            onChange={(e) => onSubjectChange(e.target.value)}
          />
        </div>
      </Form.Item>
      <Form.Item
        shouldUpdate={false}
        name={"emailBodyText"}
        label={t("email:emailBody")}
        required
        rules={[
          {
            required: true,
            message: `${t("email:emailBodyValidation")}`,
          },
        ]}
        initialValue={emailBody}
      >
        <div>
          <TinyMceEditor value={emailBody} onEditorChange={onBodyChange} />
        </div>
      </Form.Item>

      <Button
        htmlType="submit"
        type="primary"
        className="float-end gap-2 flex items-center"
        loading={isLoading}
      >
        <BiMailSend />
        {t("email:sendEmail")}
      </Button>
    </Form>
  );
}
