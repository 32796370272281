import { message } from "antd";
import { WorkerInstallationsFilterParams } from "components/workerOrders/WorkerOrderTable";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import { EventType, InstallationSchema } from "types/installation";
import { INSTALLATION_DATA_REFETCH_INTERVAL } from "utils/global/constants";

const END_POINT = "/worker-installation";
const GET_ALL_WORKER_INSTALLATION_QUERY_KEY =
  "getAllWorkerInstallationQueryKey";

export type WorkerInstallationQueryParams = WorkerInstallationsFilterParams & {
  event: EventType;
};
export const useWorkerInstallationListPaginated = (
  params: WorkerInstallationQueryParams
) => {
  return useQuery<PaginatedResponse<InstallationSchema>>({
    queryKey: [GET_ALL_WORKER_INSTALLATION_QUERY_KEY, params],
    queryFn: () =>
      new AuthorizedService()
        .get(END_POINT, params)
        .then((res: any) => res.data),
    refetchOnWindowFocus: true,
    refetchInterval: INSTALLATION_DATA_REFETCH_INTERVAL,
  });
};

export const useWorkerInstallationDone = (
  installationId: string,
  params: WorkerInstallationQueryParams
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { event: EventType }) => {
      await new AuthorizedService().post(
        `${END_POINT}/${installationId}/done`,
        data
      );
    },
    onSuccess: () => {
      message.success(t("general:updatedSuccess"));
    },
    onError: () => {
      message.error(t("general:updateFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        GET_ALL_WORKER_INSTALLATION_QUERY_KEY,
        params,
      ]);
    },
  });
};
