import { Form, Input } from "antd";
import { useInstallationWorkerOrderNumberUpdate } from "hooks/queries/useInstallation";
import { FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";

type WorkerOrderNumberForm = {
  setupOrderNumber: number;
  pickupOrderNumber: number;
};

export default function WorkerOrderNumber({
  row,
}: {
  row: InstallationSchema;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm<WorkerOrderNumberForm>();

  const { mutate } = useInstallationWorkerOrderNumberUpdate(row.id);

  const updateSetupOrderNumber = (e) => {
    const newValue = e.target.valueAsNumber || null;
    if (newValue === row.workerOrderNumber) return;
    if (form.getFieldError("setupOrderNumber").length > 0) return;

    mutate({
      setupOrderNumber: newValue,
    });
  };

  const updatePickupOrderNumber = (e) => {
    const newValue = e.target.valueAsNumber || null;
    if (newValue === row.workerPickupOrderNumber) return;
    if (form.getFieldError("pickupOrderNumber").length > 0) return;

    mutate({
      pickupOrderNumber: newValue,
    });
  };

  return (
    <Form form={form} className="small-label-form">
      <WorkerOrderInput
        name="setupOrderNumber"
        placeholder={t("orders:setup")}
        defaultValue={row.workerOrderNumber?.toString()}
        onBlur={updateSetupOrderNumber}
      />
      <WorkerOrderInput
        name="pickupOrderNumber"
        placeholder={t("orders:pickup")}
        defaultValue={row.workerPickupOrderNumber?.toString()}
        onBlur={updatePickupOrderNumber}
      />
    </Form>
  );
}

function WorkerOrderInput({
  name,
  placeholder,
  onBlur,
  defaultValue,
}: {
  name: string;
  placeholder: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  defaultValue: string | undefined;
}) {
  const { t } = useTranslation();

  const positiveNumberValidator = (_rule, value) => {
    if (!value) return Promise.resolve();

    const num = Number(value);

    if (Number.isInteger(num) && num > 0) return Promise.resolve();
    return Promise.reject(t("warnings:invalidValue"));
  };

  return (
    <Form.Item
      labelAlign="left"
      className="m-0"
      name={name}
      initialValue={defaultValue}
      rules={[
        {
          validator: positiveNumberValidator,
        },
      ]}
    >
      <Input
        style={{ width: 108 }}
        type="number"
        onBlur={onBlur}
        placeholder={placeholder}
      />
    </Form.Item>
  );
}
