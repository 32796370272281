import React from "react";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const rangePresets: { [key: string]: [Moment, Moment] } = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "This Week": [moment().startOf("week"), moment().endOf("week")],
  "Last Week": [
    moment().subtract(1, "week").startOf("week"),
    moment().subtract(1, "week").endOf("week"),
  ],
  "Past Two Months": [
    moment().subtract(2, "months").startOf("month"),
    moment(),
  ],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "This Year": [moment().startOf("year"), moment().endOf("year")],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
};

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onRangeChange: (startDate: string, endDate: string) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onRangeChange,
}) => {
  const { t } = useTranslation();

  const handleRangeChange = (dates, dateStrings) => {
    if (dates) {
      onRangeChange(dateStrings[0], dateStrings[1]);
    } else {
      onRangeChange("", "");
    }
  };

  return (
    <div>
      <h5 className="mt-3"> {t("analytics:selectDateRange")}</h5>
      <Space direction="vertical" size={12}>
        <RangePicker
          value={
            startDate && endDate ? [moment(startDate), moment(endDate)] : null
          }
          onChange={handleRangeChange}
          format="YYYY-MM-DD"
          ranges={rangePresets}
        />
      </Space>
    </div>
  );
};

export default DateRangePicker;
