import { Button, Col, Row, Image } from "antd";
import { useAuth } from "hooks/queries/useAuth";
import { AiFillWarning } from "react-icons/ai";
import { Link } from "react-router-dom";

const Index = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Row className="login-wrap">
      <Col lg={12} className="login-bg">
        <div className="logo">
          <Image preview={false} src="/img/halteverbot24.png" />
        </div>
        <Image preview={false} src="/img/login-cover.png" />
      </Col>
      <Col lg={12}>
        <div className="verify-wrap">
          <AiFillWarning />
          <h2>Oops! We are Sorry</h2>
          <p>You are not authorized to perform this action.</p>
          <div className="login-btns-wrap">
            <Link to={isLoggedIn ? "/dashboard" : "/login"}>
              <Button type="primary">Go Back</Button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
