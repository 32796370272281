import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useAnalyticsEventCount } from "hooks/queries/useAnalytics";
import { Spinner } from "components";
import { PRIMARY_COLOR } from "utils/global/constants";

const BarGraph = ({ startDate, endDate, eventType, targetName }) => {
  const { data, isLoading, error } = useAnalyticsEventCount(
    startDate,
    endDate,
    eventType,
    targetName
  );

  if (isLoading) return <Spinner />;
  if (error) return <div>Error fetching data</div>;

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill={PRIMARY_COLOR} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
