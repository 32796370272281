import { Checkbox, message } from "antd";
import { useInstallationUpdate } from "hooks/queries/useInstallation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";

export function NoSignInstallationCheckbox({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  const [optimisticCheck, setOptimisticCheck] = useState(
    installation.noSignInstallation
  );

  const { mutate, isLoading } = useInstallationUpdate(installation.id);

  const onChange = (v: boolean) => {
    setOptimisticCheck(v);
    mutate(
      { noSignInstallation: v },
      {
        onSuccess: () => {
          message.success(t("general:updatedSuccess"));
        },
        onError: () => {
          setOptimisticCheck((v) => !v);
          message.error(t("general:updateFail"));
        },
      }
    );
  };

  return (
    <div>
      <h5 className="underline">{t("orders:noSignInstallation")}:</h5>
      <div>
        <Checkbox
          checked={optimisticCheck}
          onChange={(e) => onChange(e.target.checked)}
          disabled={isLoading}
        >
          {t("orders:noSignInstallation")}
        </Checkbox>
      </div>
    </div>
  );
}
