import { useAuth } from "hooks/queries/useAuth";
import { useTranslation } from "react-i18next";
import { isWorker } from "utils/global";

const Index = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  return (
    <div>
      {t("main:welcome")}, {user?.firstName} {isWorker() && user?.lastName}
    </div>
  );
};
export default Index;
