export default function InvoiceLabelValue({
  label,
  initialValue,
  className,
  showInput,
}: {
  label: string;
  initialValue: string | null;
  className?: string;
  showInput: boolean;
}) {
  return (
    <div>
      <b>{label}: </b>
      {!showInput && <p className={className}>{initialValue || "-"}&nbsp;</p>}
    </div>
  );
}
