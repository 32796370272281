import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema, ParkedCars } from "types/installation";
import {
  extractLengthOfBoard,
  formattedDate,
  formattedDateForSingleAndMultipleDays,
  formattedExtractTime,
} from "utils/global";
import {
  getBarrierDetailsCity,
  getUpdatedTimeFrom,
  getUpdatedTimeTill,
} from "utils/global/installationHelper";
import "../../../print.scss";

type Props = {
  defaultValue: InstallationSchema;
  parkedCars: ParkedCars[] | undefined;
};

const VehicleLogOnCreationDay = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const { t } = useTranslation();

    const { defaultValue, parkedCars = [] } = props;
    const {
      additionalInformation,
      lengthOfBoard,
      setupDate,
      updatedDateRangeFrom,
      updatedDateRangeTo,
      payment,
      setupDoneDateTime,
    } = defaultValue;
    const customerAddress = getBarrierDetailsCity(defaultValue);

    const time = formattedExtractTime(setupDoneDateTime);
    const updatedTimeFrom = getUpdatedTimeFrom(defaultValue);
    const updatedTimeTill = getUpdatedTimeTill(defaultValue);

    // Create an array of rows based on the length of parkedCar
    const rows = Array.from(
      { length: Math.max(14, parkedCars.length) },
      (_, index) => {
        const car = parkedCars[index] || {};
        return (
          <tr key={index + 1}>
            <td>{index + 1}</td>
            <td>{car.licensePlate || ""}</td>
            <td>{car.brand?.name || ""}</td>
            <td>{car.carColor || ""}</td>
          </tr>
        );
      }
    );

    return (
      <div style={{ display: "none" }}>
        <div ref={ref}>
          <div id="print-page">
            <div id="page-one">
              <section className="header-section">
                <div className="header-logo">
                  <div className="img-container">
                    <img src="/img/halteverbot24.png" alt="company_logo" />
                  </div>
                </div>
                <hr />
                <div className="title">
                  <p>{t("vehicleLogPdf:vehicle_log_heading")} </p>
                </div>
              </section>
              <section className="details-section">
                <div className="title">
                  <p>
                    {`${t("vehicleLogPdf:vehicle_log_sub_heading")} - ${
                      payment.paymentId
                    }`}
                  </p>
                </div>
                <div className="details">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }} />
                        <td style={{ width: "25%" }} />
                        <td style={{ width: "25%" }} />
                        <td style={{ width: "25%" }} />
                      </tr>
                      <tr>
                        <td className="address" colSpan={4}>
                          <span>{t("vehicleLogPdf:address")}</span>
                          <span>:</span>
                          <span style={{ paddingLeft: "10px" }}>
                            {customerAddress}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="comment" colSpan={4}>
                          <span>Kommentar HVZ</span>
                          <span>:</span>
                          <span>
                            {additionalInformation?.commentHVZ || "-"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="effective-date" colSpan={2}>
                          <span>Gültigkeitsdatum</span>
                          <span>:</span>
                          <span style={{ marginLeft: "5px" }}>
                            {formattedDateForSingleAndMultipleDays(
                              formattedDate(updatedDateRangeFrom),
                              formattedDate(updatedDateRangeTo)
                            )}
                          </span>
                        </td>
                        <td colSpan={2}>
                          <span>Uhrzeit</span>
                          <span>:</span>
                          <span
                            style={{ marginLeft: "5px" }}
                          >{`${updatedTimeFrom} - ${updatedTimeTill}`}</span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "0px" }}>
                          <span>Aufgestellt am:</span>
                        </td>
                        <td style={{ border: "none" }}>
                          <span>
                            {`${formattedDate(setupDate)} ${t("general:at")}
                           ${time}`}
                          </span>
                        </td>
                        <td colSpan={2}>
                          <span>{t("vehicleLogPdf:length")}</span>
                          <span>: {extractLengthOfBoard(lengthOfBoard)}</span>
                          <span />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="client-information">
                  <div className="signature">
                    <div>{t("vehicleLogPdf:edited_created_by")}:&nbsp;</div>
                    <div className="signature-value">
                      {defaultValue.assignedTo ? (
                        <>
                          <div className="signature-image-container">
                            {defaultValue.assignedTo?.signatureFileUrl ? (
                              <img
                                className="signature-image"
                                src={defaultValue.assignedTo.signatureFileUrl}
                                alt="signature"
                              />
                            ) : null}
                          </div>
                          <div className="line"></div>
                          <div>
                            {defaultValue.assignedTo.firstName}&nbsp;
                            {defaultValue.assignedTo.lastName}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
              <section className="vehicle-list-section">
                <div className="title">
                  <p>{t("parkedCar:vehicleList")}</p>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("parkedCar:licensePlate")}</th>
                      <th>{t("parkedCar:carBrand")}</th>
                      <th>{t("parkedCar:carColor")}</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </section>
              <section className="footer-section">
                <div className="top-section">
                  <span>Halteverbot24.de e.K.</span>
                  <span> | </span>
                  <span>Bliesstraße 9</span>
                  <span> | </span>
                  <span>67059 Ludwigshafen am Rhein</span>
                </div>
                <div className="bottom-section">
                  <span>062157230726</span>
                  <span> | </span>
                  <span>info@halteverbot24.de</span>
                </div>
              </section>
              <hr />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default VehicleLogOnCreationDay;
