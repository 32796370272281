import {
  Button,
  Checkbox,
  Col,
  ColProps,
  Form,
  Input,
  message,
  Row,
} from "antd";
import {
  useInvoiceConnect,
  useInvoiceConnectionList,
} from "hooks/queries/useInvoice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { ORDER_NO_LENGTH } from "utils/global/constants";

type InvoiceConnectionForm = {
  bookingNumber: string;
};

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

export default function InvoiceConnection({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  const [connectBooking, setConnectBooking] = useState(false);

  const [form] = Form.useForm<InvoiceConnectionForm>();

  const { data } = useInvoiceConnectionList(
    installation.id,
    installation.invoice!.id // This components only renders if there is an invoice
  );

  const { mutate, isLoading: isConnectLoading } = useInvoiceConnect(
    installation.id,
    installation.invoice!.id // This components only renders if there is an invoice
  );

  const onSubmit = async (data: InvoiceConnectionForm) => {
    if (!data.bookingNumber) return;
    mutate(data, {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
        form.setFieldsValue({
          bookingNumber: "",
        });
        setConnectBooking(false);
      },
      onError: (e) => {
        if ((e as any)?.data?.message === "Installation already has invoice") {
          message.error(t("invoice:connect.alreadyConnected"));
          return;
        } else {
          message.error(t("general:updateFail"));
        }
      },
    });
  };

  const connections =
    data?.data.filter((con) => con !== installation.payment.paymentId) ?? [];

  return (
    <div className="flex flex-col gap-2">
      <Checkbox
        checked={connectBooking}
        onChange={(e) => setConnectBooking(e.target.checked)}
        className="font-600"
      >
        {t("invoice:connect.title")}
      </Checkbox>
      <div>
        {connectBooking && (
          <Form
            form={form}
            validateTrigger="onChange"
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col {...colStyles}>
                <Form.Item
                  name="bookingNumber"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value) return Promise.resolve();
                        const nan = Number.isNaN(value);
                        if (nan)
                          return Promise.reject(t("warnings:invalidValue"));
                        if (value.length !== ORDER_NO_LENGTH)
                          return Promise.reject(t("warnings:invalidValue"));
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={`${t("general:example")}: 2400000`} />
                </Form.Item>
              </Col>
              <Col {...colStyles}>
                <Button
                  key="save"
                  type="primary"
                  htmlType="submit"
                  loading={isConnectLoading}
                >
                  {t("invoice:connect.connect")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        {connections.length > 0 && (
          <div className="font-600">
            {t("invoice:connect.connectedTo")}&nbsp;
            <span style={{ fontSize: "1.2rem" }}>{connections.join(", ")}</span>
          </div>
        )}
      </div>
    </div>
  );
}
