import { useInstallationAdminInformationUpdate } from "hooks/queries/useInstallation";
import { InstallationSchema } from "types/installation";
import Notice from "../Notice";

export default function AdminNotice({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { mutate, isLoading } = useInstallationAdminInformationUpdate(
    installation.id
  );

  const onSubmit = (data: string | null) => {
    mutate({ notice: data });
  };

  return (
    <Notice
      name="notice"
      onSubmit={onSubmit}
      isLoading={isLoading}
      defaultValue={installation.notice}
    />
  );
}
