import type { ProColumns } from "@ant-design/pro-table";
import { Button, Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import { useCompanyUserListPaginated } from "hooks/queries/useCompanyUser";
import { debounce } from "lodash";
import CompanyUserModal from "pages/companyUser/CompanyUserModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiPlus, BiShow } from "react-icons/bi";
import { Link } from "react-router-dom";
import { CompanyUser } from "types/companyUser";
import { getSalutationFromGender } from "utils/global/installationHelper";

type ModalState =
  | {
      isOpen: false;
      value: null;
    }
  | {
      isOpen: true;
      value: CompanyUser;
    };

type FilterParams = {
  page: number;
  size: number;
  search: string;
};

function CompanyUserListPage() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: 100,
    search: "",
  });

  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    value: null,
  });

  const { isLoading, data } = useCompanyUserListPaginated(filterParams);
  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns<CompanyUser>[] = [
    { title: t("companyUser:field:code"), dataIndex: "code" },
    {
      title: t("companyUser:field:discount"),
      render: (_, row) => `${row.discount}%`,
    },
    {
      title: t("companyUser:field:salutation"),
      render: (_, row) => getSalutationFromGender(row.gender),
    },
    { title: t("companyUser:field:firstName"), dataIndex: "firstName" },
    { title: t("companyUser:field:lastName"), dataIndex: "lastName" },
    { title: t("companyUser:field:companyName"), dataIndex: "companyName" },
    { title: t("companyUser:field:email"), dataIndex: "email" },
    {
      title: t("companyUser:field:telephoneNumber"),
      dataIndex: "telephoneNumber",
    },
    {
      title: t("companyUser:field:cityAndZipCode"),
      dataIndex: "cityAndZipCode",
    },
    {
      title: t("companyUser:field:streetAndHouseNumber"),
      dataIndex: "streetAndHouseNumber",
    },
    {
      title: t("general:action"),
      render: (_, row: CompanyUser) => (
        <div className="flex gap-2">
          <Button
            type="primary"
            onClick={() => {
              setModalState({
                isOpen: true,
                value: row,
              });
            }}
          >
            <BiShow />
          </Button>
          <Link
            key="editLink"
            to={`/company-user/edit/${row.id}`}
            className="ant-btn ant-btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              background: "#ffb630",
            }}
          >
            <BiPencil />
          </Link>
        </div>
      ),
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, page: 1, search: value });
  }, 500);

  return (
    <React.Fragment>
      <AntdTablePro
        columns={columns}
        rowKey="id"
        dataSource={data?.items}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          current: filterParams?.page,
          pageSize: filterParams?.size,
          total: data?.count,
          onChange: (page, pageSize) => {
            setFilterParams({
              ...filterParams,
              page,
              size: pageSize,
            });
          },
        }}
        toolBarRender={[
          <Link
            key="addCompanyLink"
            to="/company-user/add"
            className="ant-btn ant-btn-primary header-add-link"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="icon">
              <BiPlus />
            </span>
            <span className="text">{t("companyUser:addCompanyUser")}</span>
          </Link>,
        ]}
        inputElement={{
          placeholder: t("main:search"),
          handleChange: (e) => getSearchKeywordsDeb(e.target.value),
          handleSearch: (value) =>
            setFilterParams({ ...filterParams, search: value }),
        }}
      />
      {modalState.isOpen && (
        <CompanyUserModal
          visible={modalState.isOpen}
          defaultValue={modalState.value}
          modalText={`${modalState.value.firstName} ${modalState.value.lastName}`}
          onHide={() => setModalState({ isOpen: false, value: null })}
        />
      )}
    </React.Fragment>
  );
}

export default CompanyUserListPage;
