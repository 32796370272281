import { Button, Modal } from "antd";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { CompanyUser } from "types/companyUser";
import { getSalutationFromGender } from "utils/global/installationHelper";

type CompanyUserModalProps = {
  visible: boolean;
  onHide: MouseEventHandler<HTMLElement>;
  modalText: string;
  defaultValue: CompanyUser;
};

export default function CompanyUserModal({
  visible,
  onHide,
  modalText,
  defaultValue,
}: CompanyUserModalProps) {
  const { t } = useTranslation();

  const {
    code,
    discount,
    gender,
    firstName,
    lastName,
    companyName,
    email,
    telephoneNumber,
    cityAndZipCode,
    streetAndHouseNumber,
  } = defaultValue;

  return (
    <Modal
      visible={visible}
      title={<div>{modalText}</div>}
      onCancel={onHide}
      width={600}
      footer={[
        <div key={Math.random()}>
          <Button key="back" onClick={onHide}>
            {t("main:close")}
          </Button>
        </div>,
      ]}
    >
      <div>
        <div className="grid grid-cols-2">
          <div>
            <b>{t("companyUser:field.code")}: </b>
            <p>{code}</p>
          </div>
          <div>
            <b>{t("companyUser:field.discount")}: </b>
            <p>{discount}%</p>
          </div>
          <div>
            <b>{t("companyUser:field.salutation")}: </b>
            <p>{getSalutationFromGender(gender)}</p>
          </div>
          <div>
            <b>{t("companyUser:field.firstName")}: </b>
            <p>{firstName}</p>
          </div>
          <div>
            <b>{t("companyUser:field.lastName")}: </b>
            <p>{lastName}</p>
          </div>
          <div>
            <b>{t("companyUser:field.companyName")}: </b>
            <p>{companyName}</p>
          </div>
          <div>
            <b>{t("companyUser:field.email")}: </b>
            <p>{email}</p>
          </div>
          <div>
            <b>{t("companyUser:field.telephoneNumber")}: </b>
            <p>{telephoneNumber}</p>
          </div>
          <div>
            <b>{t("companyUser:field.cityAndZipCode")}: </b>
            <p>{cityAndZipCode}</p>
          </div>
          <div>
            <b>{t("companyUser:field.streetAndHouseNumber")}: </b>
            <p>{streetAndHouseNumber}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
